import React from "react"

import Ref from "../components/Writings/Ref"
import Fig from "../components/Writings/Fig"
import Table from "../components/Writings/Table"

class LoanwordPhonology2018 extends React.Component {
    render() {
        return (<>
            <div className='preface'>
                <p><b>Professor:</b> Dominika M. Baran</p>
                <div className='line-break' />
            </div>
            <div className='content'><h4 id='s1'>1. Introduction</h4>
                <p>The Korean language, spoken by some 77 million people on the peninsula and abroad, is the only surviving member of the Koreanic family. It is therefore a language isolate, although many linguists, along with Ethnologue and Unesco, argue it forms a small family with Jejueo, spoken in the Jeju Self-Governing Province and considered a dialect by many Koreans.<Ref n='1' /> A minority of linguists include Korean in the controversial Altaic family, but this classification is discredited.<Ref n='2' /><sup>–</sup><Ref n='3' /></p>
                <p>Despite the isolate status, due to the nation’s complex and ancient history, Korean has a massive amount of loaned vocabulary. Most of this is from Chinese—up to 65% of the entire Korean lexicon—but these words are so important in conversation and have such a long history that speakers consider them “much more ‘native-like’ than other loanwords.”<Ref n='4' /> Meanwhile, non-Sino-Korean loanwords make up another 5% of the Korean lexicon,<Ref n='4' /> most of them stemming either from Japanese adaptations of English imported during the occupation of Korea (e.g., <i>bucket</i> [bəkət] → Japanese [baketsɯ] → Korean [pak͈es͈ɯ]), or from English directly. This paper will discuss the second case. More specifically, it will discuss the loanword phonology of vowels only, since the study of optimality-theoretic consonant assimilation and vowel epenthesis is still an active area of research and is beyond the scope of this class.<Ref n='5' /><sup>–</sup><Ref n='8' /> We will also limit our scope to direct borrowings of General American into the contemporary spoken form of the Seoul dialect—the prestige dialect of South Korea and the basis for Standard South Korean.</p>

                <h4 id='s2'>2. Monophthong Vowel Substitutions</h4>
                <p>Contemporary Korean has seven distinct vowel phonemes: [i], [e], [a], [ʌ], [o], [u] and [ɯ].<Ref n='9' /> Older texts and speakers claim three more—[ø], [y] and [ɛ]—as well as vowel length distinctions, but these have all been lost; the sounds [ø] and [y] have transformed into the glide–vowel pairings [we] and [ɥi], and the vowel [ɛ] is preserved orthographically but phonetically indistinguishable from [e], especially among younger speakers.<Ref n='10' /> This limited inventory pales in comparison to the number of English vowel phonemes and diphthongs,<Ref n='11' /> and borrowing English words into Korean is almost always imperfect.</p>
                <figure className='fig' id='fig1'>
                    <img
                        src={require("../images/posts/loanword-phonology-2018/fig1.png").default}
                        alt={"Figure 1: IPA vowel chart of GenAm English and Korean."}
                        style={{ width: "50%" }}
                    />
                    <figcaption><b>Figure 1</b> | <b>IPA vowel chart of GenAm English and Korean.</b> English and Korean sounds are in black and red, respectively. Sounds common to both languages are in orange.</figcaption>
                </figure>
                <p>In most cases, monophthong vowels are adapted by substituting with the phoneme expressing the closest articulation (height, backness and roundedness)—in other words, nearest on the IPA vowel chart in <Fig n='1' />. These substitutions are shown in <Table n='1' />.</p>
                <figure className='table' id='table1'>
                    <figcaption className='table-title'><b>Table 1</b> | <b>Adaptations of monophthong vowels in English-to-Korean loanwords.</b></figcaption>
                    <table style={{ width: "75%", textAlign: "center" }}>
                        <tr className='top bottom'>
                            <th style={{ width: "14.5%" }}>Korean</th>
                            <th style={{ width: "14.5%" }}>English</th>
                            <th style={{ width: "6.5%" }} />
                            <th style={{ width: "14.5%" }}>Korean</th>
                            <th style={{ width: "14.5%" }}>English</th>
                            <th style={{ width: "6.5%" }} />
                            <th style={{ width: "14.5%" }}>Korean</th>
                            <th style={{ width: "14.5%" }}>English</th>
                        </tr>
                        <tr>
                            <td rowSpan="6" className='bottom-light'>[i]</td>
                            <td rowSpan="3">[i]</td>
                            <td rowSpan="6" className='bottom-light' />
                            <td rowSpan="6" className='bottom-light'>[e]</td>
                            <td rowSpan="2">[e]</td>
                            <td rowSpan="6" className='bottom-light' />
                            <td rowSpan="6" className='bottom-light'>[a]</td>
                            <td rowSpan="2">[a]</td>
                        </tr>
                        <tr />
                        <tr>
                            <td rowSpan="2">[ɛ]</td>
                            <td rowSpan="2">&nbsp;&nbsp;[ɑ]*</td>
                        </tr>
                        <tr>
                            <td rowSpan="3" className='bottom-light'>[ɪ]</td>
                        </tr>
                        <tr>
                            <td rowSpan="2" className='bottom-light'>[æ]</td>
                            <td rowSpan="2" className='bottom-light'>&nbsp;&nbsp;[æ]‡</td>
                        </tr>
                        <tr />
                        <tr>
                            <td rowSpan="6" className='bottom'>[ʌ]</td>
                            <td rowSpan="2">[ʌ]</td>
                            <td rowSpan="6" className='bottom' />
                            <td rowSpan="6" className='bottom'>[o]</td>
                            <td rowSpan="3">[ɔ]</td>
                            <td rowSpan="6" className='bottom' />
                            <td rowSpan="6" className='bottom'>[u]</td>
                            <td rowSpan="3">[u]</td>
                        </tr>
                        <tr />
                        <tr>
                            <td rowSpan="2">[ɜ]</td>
                        </tr>
                        <tr>
                            <td rowSpan="3" className='bottom'>&nbsp;&nbsp;[ɑ]*</td>
                            <td rowSpan="3" className='bottom'>[ʊ]</td>
                        </tr>
                        <tr>
                            <td rowSpan="2" className='bottom'>&nbsp;&nbsp;[ə]†</td>
                        </tr>
                        <tr />
                    </table>
                    <figcaption className='indent'>* Both vowels are used to adapt [ɑ], and the pattern is sometimes inconsistent. The National Institute for Korean Language prescription is [a].</figcaption>
                    <figcaption className='indent'>† NIKL prescribes [ʌ] for adapting the schwa, but other vowels are also used.</figcaption>
                    <figcaption className='indent'>‡ [e] is the predominant and NIKL-recommended vowel used to adapt [æ], but [a] is used in certain loanwords.</figcaption>
                </figure>
                <p>The matching scheme in <Table n='1' /> has a few exceptions. For example, though [æ] is usually borrowed as [e] (e.g., <i>value</i> [vælju] → [pellju]), it is also borrowed as [a] in certain words (e.g., <i>gasoline</i> [ɡæsəlin] → [kasollin]). This may be due to orthographical influence or hypercorrection of a perceived vowel fronting—a phenomenon where open vowels become closed and back vowels migrate to the front in Korean slang.<Ref n='12' /> Other exceptions involve [ɑ] and [ǝ], which will be discussed later.</p>

                <h4 id='s3'>3. Adaptational Variability in the Open Back Unrounded Vowel</h4>
                <p>The matching scheme in <Table n='1' /> cannot predict every adaptation, and the open back unrounded vowel [ɑ], which does not exist in Korean, is one example. Although the National Institute for Korean Language—the governmental agency that regulates Standard South Korean—prescribes [a] to substitute the nonnative vowel [ɑ], both [a] and [o] are used regularly, sometimes even within the same word (e.g., <i>bodyguard</i> [bɑdigɑrd] → [podigadɯ]).</p>
                <p>The choice of vowel seems to be partly orthographic. In <Table n='2' />, we see that the [a] phoneme is used when the English word is spelled with /a/, while the [o] phoneme is used when the word is spelled with /o/ or /au/. Though some linguists argue that borrowing is a purely phonological process and that orthography is “an extra-linguistic influence […] that should be factored out,”<Ref n='13' /> Y. Kang and Daland <i>et al.</i> have found strong evidence that orthography affects loanword adaptation.<Ref n='14' /><sup>–</sup><Ref n='15' /> Even so, this orthographic pattern cannot account for all [a/o] variations, and some loanwords directly violate the rule; for example, <i>yacht</i> is borrowed as [jot<sup>h</sup>ɯ], while <i>Hollywood</i> is borrowed as [halliudɯ].</p>
                <figure className='table' id='table2'>
                    <figcaption className='table-title'><b>Table 2</b> | <b>Select comparisons of [a/o] adaptations of English words containing [ɑ].</b></figcaption>
                    <table style={{ width: "85%", textAlign: "center" }}>
                        <tr className='top bottom'>
                            <th style={{ width: "31%" }} colSpan="2">English</th>
                            <th style={{ width: "15.5%" }}>Korean</th>
                            <th style={{ width: "6%" }} />
                            <th style={{ width: "31%" }} colSpan="2">English</th>
                            <th style={{ width: "15.5%" }}>Korean</th>
                        </tr>
                        <tr>
                            <td><i><emph>a</emph>rticle</i></td>
                            <td>[ɑrtəkəl]</td>
                            <td>[<emph>a</emph>t<sup>h</sup>ik<sup>h</sup>ɯl]</td>
                            <td />
                            <td><i><emph>au</emph>ction</i></td>
                            <td>[ɑkʃən]</td>
                            <td>[<emph>o</emph>kɕʌn]</td>
                        </tr>
                        <tr>
                            <td><i><emph>a</emph>voc<emph>a</emph>do</i></td>
                            <td>[ɑvəkɑdoʊ]</td>
                            <td>[<emph>a</emph>bok<sup>h</sup><emph>a</emph>do]</td>
                            <td />
                            <td><i><emph>au</emph>dio</i></td>
                            <td>[ɑdioʊ]</td>
                            <td>[<emph>o</emph>dio]</td>
                        </tr>
                        <tr>
                            <td><i>c<emph>a</emph>rpet</i></td>
                            <td>[kɑrpət]</td>
                            <td>[k<sup>h</sup><emph>a</emph>p<sup>h</sup>et]</td>
                            <td />
                            <td><i>bl<emph>o</emph>ck</i></td>
                            <td>[blɑk]</td>
                            <td>[pɯll<emph>o</emph>k]</td>
                        </tr>
                        <tr>
                            <td><i>ch<emph>a</emph>rt</i></td>
                            <td>[tʃɑrt]</td>
                            <td>[tɕ<sup>h</sup><emph>a</emph>t<sup>h</sup>ɯ]</td>
                            <td />
                            <td><i>m<emph>o</emph>nster</i></td>
                            <td>[mɑnstɝ]</td>
                            <td>[m<emph>o</emph>nzɯt<sup>h</sup>ʌ]</td>
                        </tr>
                        <tr className='bottom'>
                            <td><i>f<emph>a</emph>ther</i></td>
                            <td>[fɑðɝ]</td>
                            <td>[p<sup>h</sup><emph>a</emph>dʌ]</td>
                            <td />
                            <td><i>pr<emph>o</emph>cess</i></td>
                            <td>[prɑsɛs]</td>
                            <td>[p<sup>h</sup>ɯr<emph>o</emph>s͈es͈ɯ]</td>
                        </tr>
                    </table>
                    <figcaption></figcaption>
                </figure>
                <p>The [a/o] variability in loanwords may occur because the two vowels are phonologically related through English. The Korean [o] is used to adapt the open-mid back rounded vowel [ɔ], a sound so similar to [ɑ] that even English speakers conflate the two—a phenomenon known as the cot–caught merger, where the pronunciations of <i>cot</i> [kɑt] and <i>caught</i> [kɔt] become indistinguishable among certain English-speaking populations. And this merger is capable of spreading; Herold proposed in 1990 that phonemic mergers, like the cot–caught merger, are usually “triggered by language or dialect contact rather than by gradual phonetic approximation.”<Ref n='16' /> Therefore, it is possible that Americans’ varying pronunciation of [ɑ/ɔ] words—and the Koreans’ perception thereof—influenced adaptation and created variability in the Korean loanwords.</p>
                <p>Another reason for variation may be that a good substitute for [ɑ] just does not exist in Korean’s vowel inventory, making [a] and [o] equally imperfect substitutions. Interestingly, the Korean vowel [ʌ] is closer on the IPA chart to [ɑ] than either [a] or [o], yet it is never used to adapt [ɑ]. This contradiction is likely due to Korean’s once strong preference for vowel harmony.<Ref n='17' /> Before it was abandoned in Modern Korean, the vowel harmony system defined three classes—bright, dark and neutral—and forced affixes and native words to use vowels from only a single class.<Ref n='18' /> Although vowel harmony is no longer prevalent today, its strength is mainly felt in native words, leading us to hypothesize that Korean adapts [ɑ] to create maximum vowel disharmony, which then makes the word sound more “foreign” and loanword-like to native ears. This is consistent with almost all examples in <Table n='2' />, where using the dark [ʌ] instead of bright [a/o] would create undesirably harmonious combinations. More research is needed to confirm this hypothesis, and to expand this hypothesis to include other loanword adaptations.</p>

                <h4 id='s4'>4. Adaptational Ambiguity in the Mid Central Vowel</h4>
                <p>The mid central vowel, known as the schwa [ə], resides in the center of the IPA vowel chart and is roughly equidistant from all the vowels available in the Korean inventory. Thus it is no surprise that, despite NIKL’s recommendation of using [ʌ], the schwa is adapted using literally every Korean vowel phoneme. Examples of each adaptation are given in <Table n='3' />.</p>
                <figure className='table' id='table3'>
                    <figcaption className='table-title'><b>Table 3</b> | <b>Select adaptations of English words containing [ə].</b></figcaption>
                    <table style={{ width: "100%", textAlign: "center" }}>
                        <tr className='top bottom'>
                            <th style={{ width: "5%" }} />
                            <th style={{ width: "28%" }} colSpan="2">English</th>
                            <th style={{ width: "14%" }}>Korean</th>
                            <th style={{ width: "6%" }} />
                            <th style={{ width: "5%" }} />
                            <th style={{ width: "28%" }} colSpan="2">English</th>
                            <th style={{ width: "14%" }}>Korean</th>
                        </tr>
                        <tr>
                            <td>[i]</td>
                            <td><i>fem<emph>i</emph>n<emph>i</emph>ne</i></td>
                            <td>[fɛmənən]</td>
                            <td>[p<sup>h</sup>em<emph>i</emph>n<emph>i</emph>n]</td>
                            <td />
                            <td>[o]</td>
                            <td><i>harm<emph>o</emph>ny</i></td>
                            <td>[hɑrməni]</td>
                            <td>[ham<emph>o</emph>ni]</td>
                        </tr>
                        <tr>
                            <td>[e]</td>
                            <td><i>lev<emph>e</emph>l</i></td>
                            <td>[lɛvəl]</td>
                            <td>[leb<emph>e</emph>l]</td>
                            <td />
                            <td>[u]</td>
                            <td><i>t<emph>u</emph>ndr<emph>a</emph></i></td>
                            <td>[təndrə]</td>
                            <td>[t<sup>h</sup><emph>u</emph>ndɯɾ<emph>a</emph>]</td>
                        </tr>
                        <tr>
                            <td>[a]</td>
                            <td><i>Caribbe<emph>a</emph>n</i></td>
                            <td>[kɛrɪbiən]</td>
                            <td>[k<sup>h</sup>eɾibi<emph>a</emph>n]</td>
                            <td />
                            <td>[ɯ]</td>
                            <td><i>penc<emph>i</emph>l</i></td>
                            <td>[pɛnsəl]</td>
                            <td>[p<sup>h</sup>ens͈<emph>ɯ</emph>l]</td>
                        </tr>
                        <tr className='bottom'>
                            <td>[ʌ]</td>
                            <td><i>medi<emph>a</emph></i></td>
                            <td>[midiə]</td>
                            <td>[midi<emph>ʌ</emph>]</td>
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                        </tr>
                    </table>
                </figure>
                <p>Like for [ɑ], there seems to be a correlation between orthography and loanword adaptation for [ə], but the correlation is so inconsistent in practice that it is not uncommon to see native speakers using nonstandard, “incorrect” adaptations in informal conversation. I demonstrated this by conducting web searches of alternate spellings for various loanwords on Naver—the most popular search engine in Korea—and comparing the number of hits returned. I searched for exact matches of each adaptation, and I discarded cases where changing the spelling changed the word’s meaning.</p>
                <p>The data in <Table n='4' /> shows that alternate adaptations are common for schwa-containing loanwords, though the pattern varies from word to word. For example, the nonstandard form [ellebeit<sup>h</sup>ʌ] is found in only 6.88% of all mentions of <i>elevator</i>, while [tɕ<sup>h</sup>ok<sup>h</sup>ollet] occurs half as often as the standard adaptation, [tɕ<sup>h</sup>ok<sup>h</sup>ollit]. (Side note: The author of this paper, himself a native Korean speaker, also uses [tɕ<sup>h</sup>ok<sup>h</sup>ollet].)</p>
                <figure className='table' id='table4'>
                    <figcaption className='table-title'><b>Table 4</b> | <b>Web searches for different adaptations of [ə], with the standard forms in bold.</b></figcaption>
                    <table style={{ width: "85%", textAlign: "center" }}>
                        <tr className='top bottom'>
                            <th style={{ width: "25%" }}>English</th>
                            <th style={{ width: "25%" }}>Korean*</th>
                            <th style={{ width: "25%" }}>Number of Results†</th>
                            <th style={{ width: "25%" }}>Percent of Total</th>
                        </tr>
                        <tr>
                            <td rowSpan="3" className='bottom-light'><i>elevator</i><br />[el<emph>ə</emph>veɪt̬ɚ]</td>
                            <td><b>[ell<emph>i</emph>beit<sup>h</sup>ʌ]</b></td>
                            <td><b>2,143,437</b></td>
                            <td><b>93.08</b></td>
                        </tr>
                        <tr>
                            <td>[ell<emph>e</emph>beit<sup>h</sup>ʌ]</td>
                            <td>158,518</td>
                            <td>6.88</td>
                        </tr>
                        <tr className='bottom-light'>
                            <td>[ell<emph>ɯ</emph>beit<sup>h</sup>ʌ]</td>
                            <td>557</td>
                            <td>0.02</td>
                        </tr>
                        <tr>
                            <td rowSpan="3" className='bottom'><i>chocolate</i><br />[tʃɑːkl<emph>ə</emph>t]</td>
                            <td><b>[tɕ<sup>h</sup>ok<sup>h</sup>oll<emph>i</emph>t]</b></td>
                            <td><b>2,555,832</b></td>
                            <td><b>66.46</b></td>
                        </tr>
                        <tr>
                            <td>[tɕ<sup>h</sup>ok<sup>h</sup>oll<emph>e</emph>t]</td>
                            <td>1,289,654</td>
                            <td>33.54</td>
                        </tr>
                        <tr className='bottom'>
                            <td>[tɕ<sup>h</sup>ok<sup>h</sup>oll<emph>a</emph>t]</td>
                            <td>140</td>
                            <td>0.00</td>
                        </tr>
                    </table>
                    <figcaption className='indent'>* To conserve space, only the top three alternates for each loanword are shown in this table.</figcaption>
                    <figcaption className='indent'>† The numbers for [e] combines the two phonologically identical spellings, ㅐ and ㅔ.</figcaption>
                </figure>

                <h4 id='s5'>5. Diphthongs, Glide–Vowel Pairs and R-Colored Vowels</h4>
                <p>Unlike English, Korean does not have “true” diphthongs, in that two vowels cannot be combined together in a single syllable. As a result, “the concept of an English diphthong is hard for Koreans to perceive,” and diphthongs are instead perceived and adapted as two separate syllables.<Ref n='19' /> For example, the English word <i>bacon</i> [beɪ.kən] is borrowed as [pe.i.khʌn], with three separate syllables.</p>
                <p>While Korean may appear to have diphthongs, what Koreans think of and write as diphthongs are actually single vowels preceded by glides.<Ref n='19' /> There are 10 such pairs permitted in the Korean language: [je, ja, jʌ, jo, ju], [we, wa, wʌ], and [ɰi, ɥi],<Ref n='9' /> and the adaptation of such pairs in English is predictable and straightforward. In cases where the appropriate glide–vowel pair does not exist in Korean, the glide is dropped and the sound is adapted as just the vowel. For example, <i>Yiddish</i> [jɪdɪʃ] is borrowed as [idiɕi], and <i>woman</i> [wʊmən] is borrowed as [umʌn].</p>
                <p>Just as the English concept of diphthongs does not exist in Korean, the concept of r-coloredness does not exist either. Therefore, any r-colored vowels in English are adapted without the r-sound (e.g., <i>north</i> [nɔrθ] → [nos͈ɯ]).</p>
            </div>
            <div className='line-break' />

            <div className='refs'>
                <h4>References</h4>
                <ol>
                    <li id='ref1'>Yang, Changyong <i>et al.</i> “Toward a linguistically realistic assessment of language vitality: The case of Jejueo.” <i>Language Documentation & Conservation</i>, vol. 11, 2017, pp. 103–113.</li>
                    <li id='ref2'>Campbell, Lyle. <i>A Glossary of Historical Linguistics</i>. University of Utah Press, 2007.</li>
                    <li id='ref3'>Georg, Stefan <i>et al.</i> “Telling general linguists about Altaic.” <i>Journal of Linguistics</i>, vol. 35, no. 1, 1999, pp. 65–98.</li>
                    <li id='ref4'>Sohn, Ho-Min. “Korean in contact with Chinese.” <i>Korean Language in Culture and Society</i>, edited by Ho-Min Sohn, University of Hawaii Press, 2006.</li>
                    <li id='ref5'>Davis, Stuart and Hyunsook Kang. “English loanwords and the word-final [t] problem in Korean.” <i>Language Research</i>, vol. 42, no. 2, 2006, pp. 253–274.</li>
                    <li id='ref6'>Oh, Mira. “Adaptation of English complex words into Korean.” <i>Journal of East Asian Linguistics</i>, vol. 21, no. 3, 2012, pp. 267–304.</li>
                    <li id='ref7'>Kim, Hyoju. “Contextual distribution of English loanword word-initial tensification in Korean.” <i>Studies in Phonetics, Phonology and Morphology</i>, vol. 22, no. 2, 2016, pp. 245–288.</li>
                    <li id='ref8'>Seo, Hongwon. “Phonotactic adaptation in loanwords: Insertion vs. deletion.” <i>The Linguistic Association of Korea Journal</i>, vol. 24, no. 3, 2016, pp. 37–62.</li>
                    <li id='ref9'>Gim, Sheon-Gi. <i>Phonetics of Korean.</i> Hanul, 2007.</li>
                    <li id='ref10'>Kwak, Chung-Gu. “The vowel system of contemporary Korean and direction of change.” <i>Journal of Korea Linguistics</i>, vol. 41, 2003, pp. 59–91.</li>
                    <li id='ref11'>Wells, J. C. <i>Accents of English.</i> Cambridge University Press, 1982.</li>
                    <li id='ref12'>Hong, Eun-Young. <i>A Study on High Vowel Fronting in Korean.</i> Master’s thesis, Seoul National University, 2012.</li>
                    <li id='ref13'>Kang, Yoonjung. “English /z/ in 1930s Korean.” <i>Proceedings of the 2nd International Conference on East Asian Linguistics</i>, Working paper, 2009.</li>
                    <li id='ref14'>Kang, Yoonjung. “Loanword phonology.” <i>Companion to Phonology</i>, edited by Marc van Oostendorp <i>et al.</i>, vol. 4, Wiley-Blackwell, 2011.</li>
                    <li id='ref15'>Daland, Robert <i>et al.</i> “When in doubt, read the instructions: Orthographic effects in loanword adaptation.” <i>Lingua</i>, vol. 159, 2015, pp. 70–92.</li>
                    <li id='ref16'>Herold, Ruth. <i>Mechanisms of Merger: The Implementation and Distribution of the Low Back Merger in Eastern Pennsylvania. Dissertation, University of Pennsylvania, 1990.</i></li>
                    <li id='ref17'>Lyovin, Anatole <i>et al.</i> <i>An Introduction to the Languages of the World.</i> Oxford University Press, 2016.</li>
                    <li id='ref18'>Chung, Chin-Wan. “An optimality-theoretic account of vowel harmony in Korean ideophones.” <i>Studies in Phonetics, Phonology and Morphology</i>, vol. 6, no. 2, 2000, pp. 431–450.</li>
                    <li id='ref19'>Cho, Junmo and Hae-Kyeong Park. “A comparative analysis of Korean–English phonological structures and processes for pronunciation pedagogy in interpretation training.” <i>Meta</i>, vol. 51, no. 2, 2006, pp. 229–246.</li>
                </ol>
            </div>
        </>)
    }
}

export default LoanwordPhonology2018
