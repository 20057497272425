import React from "react"

import Anchor from "../components/Writings/Anchor"
import Ref from "../components/Writings/Ref"
import Table from "../components/Writings/Table"

class NumeralClassifiers2018 extends React.Component {
    render() {
        return (<>
            <div className='preface'>
                <p><b>Professor:</b> Dominika M. Baran</p>
                <div className='line-break' />
            </div>

            <div className='content'>
                <h4 id='s1'>1. Introduction</h4>
                <p>Japanese and Korean, spoken in mainland Japan and the Korean peninsula, respectively, are Far East Asian languages claiming more than 200 million total speakers. Though they share no credible genetic relationship<Ref n='1' /><sup>–</sup><Ref n='2' />—Japanese is grouped with the Ryukyuan languages as part of the Japonic family, while Korean is a possible isolate in the Koreanic family, with a disputed language status for the Jeju dialect<Ref n='3' />—the two languages share surprisingly many linguistic features. Both Japanese and Korean have an agglutinative subject–object–verb (SOV) typology, with particle systems, topic markers and classifiers. They are both pro-drop, null-subject and discourse-oriented languages, where subject noun phrases or pronouns are often omitted and inferred from context.<Ref n='4' /> And they both use an elaborate, multilayered honorifics system that reflects relative differences in social and cultural rank.<Ref n='5' /> Combined with the languages’ heavy use of Chinese loanwords,<Ref n='6' /><sup>–</sup><Ref n='7' /> these grammatical and syntactical similarities accumulate to such an extent that some sentences can be aligned and translated on a word-by-word basis, and the languages naturally lend themselves to linguistic comparisons.</p>
                <p>In this paper, I aimed to investigate whether classifiers are also similar across the two languages and, if so, in which areas and by how much. We will limit our scope to the contemporary spoken forms of the Seoul and Tokyo dialects—the prestige dialects and bases for standardization in the two languages. All examples given will be in the most common romanization systems for the languages—the Modified Hepburn romanization for Japanese, the Revised Romanization of Korean and the Hanyu Pinyin romanization for Standard Chinese.</p>

                <h4 id='s2'>2. Fieldwork</h4>
                <p>For this study, I recruited Eunyoung Kim, a lecturing fellow of Asian and Middle Eastern Studies at Duke University. As a middle-aged, college-educated native speaker in Korean with conversational fluency in Japanese and English, she could give contemporary examples of linguistic features in all three languages, making interlinguistic comparisons a natural topic of study. I asked questions primarily in Korean—the speaker’s language of choice—though I used English when my own Korean skills were found to be lacking. To obtain examples of Korean classifiers, I used the generic inanimate classifier <i>gae</i> and asked her to correct the given phrases.</p>
                <p>The interviews with the speaker were divided into three hourlong sessions. In the first, I asked her to translate simple number+classifier phrases, in order to compile a list of common classifiers and derive their similarities and grouping patterns. In the second and third, I asked her to translate entire sentences containing such phrases, in order to uncover syntax rules of classifier use and placement. All following sentence examples, in Japanese and in Korean, are from these sessions and were translated and verified by the speaker.</p>

                <h4 id='s3'>3. Classifiers and “Measure Words”</h4>
                <p>Though classifiers are sometimes called “measure words,” the two are different concepts serving different functions. For example, when Western textbooks and instructors give such analogies as <i>two cups of coffee</i>, the <i>cups</i> is a measure word, as it creates and assigns a unit of measure to an otherwise quantitatively vague “mass noun.” Measure words are not intrinsic to a given noun, as different units—<i>two drops</i>, <i>two pots</i>, <i>two gallons</i>—can be used to quantify the noun in different amounts.<Ref n='8' /> Like in most languages, measure words exist in Japanese and Korean, as shown in <Anchor n='1' text="(1)" />.</p>
                <p id='anchor1' style={{ overflowX: "auto" }}>
                    <pre> (1)  JPN: <i>Sōda  o    san-bai kudasai.</i></pre>
                    <pre>      KOR: <i>Saida-reul se  jan juseyo.</i></pre>
                    <pre>           soda  ACC  3   CL  give-POL-IMP</pre>
                    <pre> </pre>
                    <pre>      “Three glasses of soda, please.”</pre>
                </p>
                <p>Meanwhile, “true” classifiers convey the inherent countable unit of a noun and is intrinsic to the object being discussed.<Ref n='8' /> In English, these objects are usually count nouns and can be made plural without additional grammatical effort. In Japanese and Korean, however, classifiers are needed to convey such quantities, as shown in <Anchor n='2' text="(2)" />.</p>
                <p id='anchor2' style={{ overflowX: "auto" }}>
                    <pre> (2)  JPN: <i>Inu-ga  nan     -biki imasu    ka?</i></pre>
                    <pre>      KOR: <i>Gae-ga  myeot    mari isseoyo?</i></pre>
                    <pre>           dog NOM how many CL   be-POL   Q</pre>
                    <pre> </pre>
                    <pre>      “How many dogs are there?”</pre>
                </p>
                <p>Because Japanese and Korean make no formal distinction between classifiers and measure words, problems arise when a marker appears to fit both categories. For example, in <Anchor n='3' text="(3)" />, both sets of phrases use the classifiers <i>hon</i> (Japanese; long and thin) and <i>byeong</i> (Korean; bottles). But, in <Anchor n='3' text="(3b)" />, the same classifiers that counted discrete objects in <Anchor n='3' text="(3a)" /> now function more like measure words, assigning a unit to the uncountable liquid beer.</p>
                <p id='anchor3' style={{ overflowX: "auto" }}>
                    <pre> (3)(a)  JPN: <i>Garasubin    o   ni-hon    hirotta.</i></pre>
                    <pre>         KOR: <i>Yuribyeong  -eul du byeong juseotda.</i></pre>
                    <pre>              glass bottle ACC 2  CL     find-PRET</pre>
                    <pre> </pre>
                    <pre>         “I found two glass bottles.”</pre>
                    <pre> </pre>
                    <pre>    (b)  JPN: <i>Bīru   o    ni-hon    nonda.</i></pre>
                    <pre>         KOR: <i>Maekju-reul du byeong masyeotda.</i></pre>
                    <pre>              beer   ACC  2  CL     drink-PRET</pre>
                    <pre> </pre>
                    <pre>         “I drank two bottles of beer.”</pre>
                </p>
                <p>This paper primarily discusses markers that exist exclusively as classifiers, which are a relatively unusual feature in the linguistic world compared to measure words.<Ref n='9' /></p>

                <h4 id='s4'>4. Classifier Types and Vocabulary</h4>
                <p>Classifiers group objects not by quantities or units of measure, but by concrete, qualitative features like type, size, shape and texture. A list of some common classifiers in Japanese and in Korean are given in <Table n='1' /> and <Table n='2' />, respectively.</p>
                <figure className='table' id='table1'>
                    <figcaption className='table-title'><b>Table 1</b> | <b>Examples of common classifiers in Japanese (from the speaker).</b></figcaption>
                    <table style={{ width: "100%", textAlign: "center" }}>
                        <tr className='top bottom'>
                            <th style={{ width: "10%" }}>Kanji</th>
                            <th style={{ width: "10%" }}>Rōmaji*</th>
                            <th style={{ width: "30%" }}>Type</th>
                            <th style={{ width: "70%" }}>Examples</th>
                        </tr>
                        <tr>
                            <td>個</td>
                            <td><i>ko</i></td>
                            <td>Generic inanimate</td>
                            <td>Tables, frames, fingers, tomatoes, shellfish</td>
                        </tr>
                        <tr>
                            <td>人</td>
                            <td><i>nin</i></td>
                            <td>People</td>
                            <td>People</td>
                        </tr>
                        <tr>
                            <td>名</td>
                            <td><i>mei</i></td>
                            <td>People (polite)</td>
                            <td>Customers, guests</td>
                        </tr>
                        <tr>
                            <td>本</td>
                            <td><i>hon</i></td>
                            <td>Long, thin</td>
                            <td>Pens, straws, bottles, carrots, legs, trees</td>
                        </tr>
                        <tr>
                            <td>枚</td>
                            <td><i>mai</i></td>
                            <td>Flat, thin, individual</td>
                            <td>Papers, photos, coins, doors, leaves, shirts</td>
                        </tr>
                        <tr>
                            <td>台</td>
                            <td><i>dai</i></td>
                            <td>Machinery</td>
                            <td>Bikes, cars, planes, appliances, phones</td>
                        </tr>
                        <tr>
                            <td>匹</td>
                            <td><i>hiki</i></td>
                            <td>Animals</td>
                            <td>Dogs, horses, reptiles, fish, shrimp, insects</td>
                        </tr>
                        <tr>
                            <td>冊</td>
                            <td><i>satsu</i></td>
                            <td>Bound paper</td>
                            <td>Books, magazines</td>
                        </tr>
                        <tr className='bottom'>
                            <td>束</td>
                            <td><i>taba</i></td>
                            <td>Bunches</td>
                            <td>Bouquets, green onions, napa cabbages†</td>
                        </tr>
                    </table>
                    <figcaption className='indent'>* Due to pronunciation rules in Japanese, these romanizations may change when preceded by certain phonemes.</figcaption>
                    <figcaption className='indent'>† Also used as a measure word to count bunches of fruit.</figcaption>
                </figure>
                <p style={{ margin: "2em 0" }} />
                <figure className='table' id='table2'>
                    <figcaption className='table-title'><b>Table 2</b> | <b>Examples of common classifiers in Korean (from the speaker).</b></figcaption>
                    <table style={{ width: "100%", textAlign: "center" }}>
                        <tr className='top bottom'>
                            <th style={{ width: "10%" }}>Hanja*</th>
                            <th style={{ width: "10%" }}>Romanized</th>
                            <th style={{ width: "30%" }}>Type</th>
                            <th style={{ width: "70%" }}>Examples</th>
                        </tr>
                        <tr>
                            <td>個</td>
                            <td><i>gae</i></td>
                            <td>Generic inanimate</td>
                            <td>Tables, straws, coins, carrots, legs, shellfish</td>
                        </tr>
                        <tr>
                            <td>名</td>
                            <td><i>myeong</i></td>
                            <td>People</td>
                            <td>People</td>
                        </tr>
                        <tr>
                            <td />
                            <td><i>bun</i></td>
                            <td>People (polite)</td>
                            <td>Customers, guests, teachers, elders</td>
                        </tr>
                        <tr>
                            <td />
                            <td><i>jaru</i></td>
                            <td>Long, thin tools</td>
                            <td>Guns, knives, pencils, pens, shovels</td>
                        </tr>
                        <tr>
                            <td>章</td>
                            <td><i>jang</i></td>
                            <td>Flat, thin, individual</td>
                            <td>Papers, photos, leaves</td>
                        </tr>
                        <tr>
                            <td>臺</td>
                            <td><i>dae</i></td>
                            <td>Machinery</td>
                            <td>Bikes, cars, planes, appliances, phones</td>
                        </tr>
                        <tr>
                            <td />
                            <td><i>mari</i></td>
                            <td>Animals</td>
                            <td>Dogs, horses, reptiles, fish, shrimp, insects</td>
                        </tr>
                        <tr>
                            <td>卷</td>
                            <td><i>gwon</i></td>
                            <td>Bound paper</td>
                            <td>Books, magazines</td>
                        </tr>
                        <tr className='bottom'>
                            <td />
                            <td><i>songi</i></td>
                            <td>Blossoms, bunches</td>
                            <td>Flowers†</td>
                        </tr>
                    </table>
                    <figcaption className='indent'>* Blank entries indicate native classifiers with no hanja equivalent.</figcaption>
                    <figcaption className='indent'>† Also used as a measure word to count bunches of fruit.</figcaption>
                </figure>
                <p>The examples above show apparent similarities between the classifiers in Japanese and Korean. Both languages use the same generic inanimate classifier (<i>ko</i>, <i>gae</i>) to count objects lacking more specific classifiers, or an object whose proper classifier is unknown. The languages also share many basic groupings, such as people (in both the informal and formal sense), machinery, animals and bound paper, among several others.</p>
                <p>Through the Chinese character representations of these morphemes—kanji in Japanese and hanja in Korean—many of these similarities can be traced back to direct borrowing from Chinese.<Ref n='10' /> For example, the generic inanimate classifier in both Japanese and Korean (<i>ko</i>, <i>gae</i>) is represented by 個, the traditional form of the Mandarin classifier for people and generic objects, <i>gè</i> 个. Similarly, the machinery classifier <i>dae</i> 臺 in Korean uses the traditional form of the same classifier in Japanese (<i>dai</i> 台) and Mandarin (<i>tái</i> 台)</p>
                <p>In classifiers without Mandarin equivalents, the Chinese character representations reveal more tenuous similarities. For example, the Japanese polite classifier for people (<i>mei</i> 名) shares its character with the Korean informal classifier for people, <i>myeong</i> 名. And the kanji for the Japanese animal classifier (<i>hiki</i> 匹) is the hanja for <i>pil</i> 匹, the seldom used classifier for cows and horses in Korean.</p>
                <p>Despite similarities, the classifiers in Japanese and Korean have substantial differences. This is evident in the Japanese <i>hon</i>, the ubiquitous classifier used to count practically any long, thin object—pencils, straws, shovels, bottles, cigars, fingers, carrots, trees and even electric utility poles. The closest Korean equivalent <i>jaru</i>, however, covers only certain long, thin tools like pencils, shovels, knives and rifles; the rest are either given their own classifiers (<i>gaebi</i> for cigarettes, <i>byeong</i> for bottles, <i>geuru</i> for trees) or assigned to the generic <i>gae</i>. Similarly, while the Japanese classifier <i>mai</i> describes anything flat, from papers and doors to coins and T-shirts, the analogous <i>jang</i> in Korean covers a much more exclusive set—papers, photos and leaves.</p>
                <p>The overall pattern appears to be that contemporary Japanese groups objects by a broader set of physical traits, while Korean uses object-specific classifiers and the generic classifier more frequently. The speaker’s behavior in the interview lent credibility to this hypothesis. As she translated noun–classifier (N+Cl) phrases into Japanese, she was surprised by the range of nouns classified by <i>hon</i>, and she struggled to recall some hyper-specific Korean classifiers (e.g., <i>pogi</i> for napa cabbages).</p>

                <h4 id='s5'>5. Classifier Syntax</h4>
                <p>Japanese and Korean are known to have similar syntactic structure,<Ref n='11' /> and the similarities seem to extend into the classifiers. Since the clause elements—subject, object and verb—in agglutinative languages are denoted by particles and not typology, the word order in a sentence can be somewhat flexible, as seen in <Anchor n='4' text="(4)" />. Asterisks indicate phrases that are unnatural or grammatically incorrect.</p>
                <p id='anchor4' style={{ overflowX: "auto" }}>
                    <pre> (4)(a)  JPN: <i>Gakusei       o   san-nin    yobimashita.</i></pre>
                    <pre>         KOR: <i>Haksaeng-deul-eul se  myeong bulleosseoyo.</i></pre>
                    <pre>              student  PL   ACC 3   CL     call-POL-PRET</pre>
                    <pre> </pre>
                    <pre>    (b)  JPN: <i>Gakusei       san-nin    o   yobimashita.</i></pre>
                    <pre>         KOR: <i>Haksaeng-deul se  myeong-eul bulleosseoyo.</i></pre>
                    <pre>              student  PL   3   CL     ACC call-POL-PRET</pre>
                    <pre> </pre>
                    <pre>    (c)  JPN: <i>Gakusei       san-nin    yobimashita.</i></pre>
                    <pre>         KOR: <i>Haksaeng-deul se  myeong bulleosseoyo.</i></pre>
                    <pre>              student  PL   3   CL     call-POL-PRET</pre>
                    <pre> </pre>
                    <pre>    (d)* JPN: <i>San-nin    o   gakusei       yobimashita.</i></pre>
                    <pre>       * KOR: <i>Se  myeong-eul haksaeng-deul bulleosseoyo.</i></pre>
                    <pre>              3   CL     ACC student  PL   call-POL-PRET</pre>
                    <pre> </pre>
                    <pre>    (e)* JPN: <i>San-nin    gakusei       yobimashita.</i></pre>
                    <pre>       * KOR: <i>Se  myeong haksaeng-deul bulleosseoyo.</i></pre>
                    <pre>              3   CL     student  PL   call-POL-PRET</pre>
                    <pre> </pre>
                    <pre>    (f)  JPN: <i>San-nin    no  gakusei       o   yobimashita.</i></pre>
                    <pre>       * KOR: <i>Se  myeong-ui  haksaeng-deul-eul bulleosseoyo.</i></pre>
                    <pre>              3   CL     GEN student  PL   ACC call-POL-PRET</pre>
                    <pre> </pre>
                    <pre>    (g)* JPN: <i>San-nin    gakusei       yobimashita.</i></pre>
                    <pre>       * KOR: <i>Se  myeong haksaeng-deul bulleosseoyo.</i></pre>
                    <pre>              3   CL     student  PL   call-POL-PRET</pre>
                    <pre> </pre>
                    <pre>         “I called three students.”</pre>
                </p>
                <p>Despite the usual flexibility in word order, examples <Anchor n='4' text="(4a–c)" /> show that the noun phrase must precede the classifier phrase, and the position or existence of the accusative particle is variable. When the noun follows the classifier, however, the reversed word order is allowed only in <Anchor n='4' text="(4f)" />—where the classifier phrase is marked genitive—and only in Japanese. The same example in Korean is understandable but unnatural.</p>
                <p>Though the literal meanings of <Anchor n='4' text="(4)" /> are identical, they each differ in nuance, where the word preceding the accusative particle is understood to carry stress. For example, <Anchor n='4' text="(4a)" /> and <Anchor n='4' text="(4f)" /> emphasizes the students themselves, while <Anchor n='4' text="(4b)" /> emphasizes the number of students. And in <Anchor n='4' text="(4c)" />, where particles have been omitted altogether, the sentence carries an overall neutral tone.</p>
                <p>In all examples in <Anchor n='4' text="(4)" />, the N+Cl phrases are uninterrupted except by particles. We show in <Anchor n='5' text="(5)" /> that any other element, like a prepositional phrase, cannot interrupt the N+Cl combination. Again, asterisks indicate phrases that are unnatural or grammatically incorrect.</p>
                <p id='anchor5' style={{ overflowX: "auto" }}>
                    <pre> (5)(a)  JPN: <i>Tomodachi   yon-nin    ga  pātī  ni  kita.</i></pre>
                    <pre>         KOR: <i>Chingu-deul ne  myeong-i   pati -e   watda.</i></pre>
                    <pre>              friend PL   4   CL     NOM party DAT come-PRET</pre>
                    <pre> </pre>
                    <pre>    (b)  JPN: <i>Pātī  ni  tomodachi   yon-nin    ga  kita.</i></pre>
                    <pre>         KOR: <i>Pati -e   chingu-deul ne  myeong-i   watda.</i></pre>
                    <pre>              party DAT friend PL   4   CL     NOM come-PRET</pre>
                    <pre> </pre>
                    <pre>    (c)* JPN: <i>Tomodachi   ga  pātī  ni  yon-nin    kita.</i></pre>
                    <pre>       * KOR: <i>Chingu-deul-i   pati -e   ne  myeong watda.</i></pre>
                    <pre>              friend PL   NOM party DAT 4   CL     come-PRET</pre>
                    <pre> </pre>
                    <pre>         “Four friends came to the party.”</pre>
                    <pre> </pre>
                    <pre>    (d)  JPN: <i>Tomodachi   pātī  ni  yon-nin    ga  kita.</i></pre>
                    <pre>         KOR: <i>Chingu-deul pati -e   ne  myeong-i   watda.</i></pre>
                    <pre>              friend PL   party DAT 4   CL     NOM come-PRET</pre>
                    <pre> </pre>
                    <pre>         “Four people came to the friends’ party.”</pre>
                </p>
                <p>In <Anchor n='5' text="(5a)" /> and <Anchor n='5' text="(5b)" />, the prepositional phrase to the party exists at either side of the N+Cl combination; when the phrase invades the combination, the examples <Anchor n='5' text="(5c)" /> are understandable yet awkward. Interestingly, if the prepositional phrase interrupts the N+Cl and the nominative marker is separated from the noun, the sentence is correct, but the meaning changes. In <Anchor n='5' text="(5d)" />, the friend assumes a genitive particle and is taken like a modifier. This holds true for both languages.</p>

                <h4 id='s6'>6. Classifier Obsolescence</h4>
                <p>In all three interviews, the classifiers given by the speaker did not always match those found in literature. The archetypal example was classifiers for animals, as shown in <Table n='3' />. The Japanese data is from Downing,<Ref n='12' /> and the Korean data is from Woo.<Ref n='13' /></p>
                <figure className='table' id='table3'>
                    <figcaption className='table-title'><b>Table 3</b> | <b>All animal classifiers in Japanese and Korean use, as reported in Downing and Woo.<Ref n='12' /><sup>–</sup><Ref n='13' /></b></figcaption>
                    <table style={{ width: "100%", textAlign: "center" }}>
                        <tr className='top bottom'>
                            <th style={{ width: "8%" }}>Kanji</th>
                            <th style={{ width: "8%" }}>Jpn.</th>
                            <th style={{ width: "32%" }}>Animal Type</th>
                            <th style={{ width: "4%" }} />
                            <th style={{ width: "8%" }}>Hanja</th>
                            <th style={{ width: "8%" }}>Kor.</th>
                            <th style={{ width: "32%" }}>Animal Type</th>
                        </tr>
                        <tr>
                            <td>匹</td>
                            <td><i>hiki</i></td>
                            <td>Generic</td>
                            <td />
                            <td />
                            <td><i>mari</i></td>
                            <td>Generic</td>
                        </tr>
                        <tr>
                            <td>頭</td>
                            <td><i>tō</i></td>
                            <td>Large (cows, elephants, whales)</td>
                            <td />
                            <td>頭</td>
                            <td><i>du</i></td>
                            <td>Livestock (cows, pigs, horses)</td>
                        </tr>
                        <tr>
                            <td>羽</td>
                            <td><i>wa</i></td>
                            <td>Birds, rabbits</td>
                            <td />
                            <td>匹</td>
                            <td><i>pil</i></td>
                            <td>Cows, horses</td>
                        </tr>
                        <tr>
                            <td>杯</td>
                            <td><i>hai</i></td>
                            <td>Squids, octopuses</td>
                            <td />
                            <td />
                            <td />
                            <td />
                        </tr>
                        <tr className='bottom'>
                            <td>尾</td>
                            <td><i>bi</i></td>
                            <td>Fish</td>
                            <td />
                            <td />
                            <td />
                            <td />
                        </tr>
                    </table>
                </figure>
                <p>Although different classifiers exist to count different animals, the speaker used the generic classifier (<i>hiki</i>, <i>mari</i>) to count all animals, regardless of size or type. When asked about the other classifiers, the speaker confirmed the ones for livestock (<i>tō</i>, <i>du</i>, <i>pil</i>) but said they are used mostly by professionals, such as farmers and ranchers, and not in everyday conversation. She did not recognize <i>bi</i>, <i>hai</i> and <i>wa</i> for rabbits.</p>
                <p>This appears to be indicative of larger trend of classifier obsolescence, where the languages’ speakers discard obsolete, hyper-specific classifiers in favor of a concentrated set of generic classifiers. The speaker demonstrated this when she used the Japanese <i>ko</i> (generic inanimate) to count knives, when the “proper” classifier for knives and other long, handled implements would have been <i>chō</i>.<Ref n='12' /> This corroborated the results of Downing’s questionnaire of 154 Japanese classifiers, which revealed that even native Japanese speakers confused some classifiers, and that some classifiers were not in use at all.</p>
                <p>As for the animal examples in <Table n='3' />, the speaker gave her own explanation for the obsolescence of certain classifiers. She reasoned that livestock like cows and horses were crucial and highly valued in the pre-industrial, predominantly agricultural Japan and Korea, warranting their own classifiers. However, as the nations industrialized and urbanized, as the need for livestock dwindled—to the point where the middle and lower classes began eating beef, an unthinkable thought just decades ago—so did the use of their classifiers. More research is needed to confirm this hypothesis, and to extrapolate the reasoning to the obsolescence of other classifiers.</p>
            </div>
            <div className='line-break' />

            <div className='refs'>
                <h4>References</h4>

                <ol>
                    <li id='ref1'>Campbell, Lyle. <i>A Glossary of Historical Linguistics.</i> University of Utah Press, 2007.</li>
                    <li id='ref2'>Georg, Stefan <i>et al.</i> “Telling general linguists about Altaic.” <i>Journal of Linguistics</i>, vol. 35, no. 1, 1999, pp. 65–98.</li>
                    <li id='ref3'>Yang, Changyong <i>et al.</i> “Toward a linguistically realistic assessment of language vitality: The case of Jejueo.” <i>Language Documentation & Conservation</i>, vol. 11, 2017, pp. 103–113.</li>
                    <li id='ref4'>Huang, C. T. James. “On the distribution and reference of empty pronouns.” <i>Linguistic Inquiry</i>, vol. 15, no. 4, 1984, pp. 531–574.</li>
                    <li id='ref5'>Hijirida, Kyoko and Ho-Min Sohn. “Cross-cultural patterns of honorifics and sociolinguistic sensitivity to honorific variables: Evidence from English, Japanese, and Korean.” <i>Research on Language & Social Interaction</i>, vol. 19, no. 3, 1986, pp. 365–401.</li>
                    <li id='ref6'>Shibatani, Masayoshi. <i>The Languages of Japan.</i> Cambridge UP, 1990.</li>
                    <li id='ref7'>Sohn, Ho-Min. “Korean in contact with Chinese.” <i>Korean Language in Culture and Society</i>, edited by Ho-Min Sohn, University of Hawaii Press, 2006.</li>
                    <li id='ref8'>Cheng, Lisa Lai-Shen and Rint Sybesma. “Bare and not-so-bare nouns and the structure of NP.” <i>Linguistic Inquiry</i>, vol. 30, no. 4, 1999, pp. 509–542.</li>
                    <li id='ref9'>Gil, David. “Numeral classifiers.” <i>World Atlas of Language Structures Online</i>, edited by Matthew S. Dryer and Martin Haspelmath, Max Planck Institute for Evolutionary Anthropology, 2013.</li>
                    <li id='ref10'>T’sou, Benjamin K. “Language contact and lexical innovation.” <i>New Terms for New Ideas: Western Knowledge and Lexical Change in Late Imperial China</i>, edited by Michael Lackner <i>et al.</i>, Brill, 2001.</li>
                    <li id='ref11'>Sibata, Takesi. “Some syntactic similarities between Turkish, Korean, and Japanese.” <i>Central Asiatic Journal</i>, vol. 23, no. 3/4, 1979, pp. 293–296.</li>
                    <li id='ref12'>Downing, Pamela. <i>Numeral Classifier Systems: The Case of Japanese.</i> vol. 4, John Benjamins Pub., 1996. <i>Studies in Discourse and Grammar</i>, Sandra A. Thompson and Paul J. Hopper.</li>
                    <li id='ref13'>Woo, Hyeong-Sik. 한국어 분류사의 범주화 기능 연구 [<i>A Study on the Categorical Function of Korean Classifiers</i>]. Pagijong, 1999.</li>
                </ol>
            </div>
        </>)
    }
}

export default NumeralClassifiers2018
