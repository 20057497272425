import React from "react"

class ChristmasTrees2018 extends React.Component {
    render() {
        return (<>
            <div className='preface'>
                <p><b>Editor:</b> Mark Stencel</p>
                <figure class='fig'>
                    <img
                        src={require("../images/posts/christmas-trees-2018/roadside-trees.png").default}
                        alt={"A roadside Christmas tree lot in Gibsonville, N.C."}
                        style={{ width: "100%" }}
                    />
                    <figcaption>A roadside Christmas tree lot in Gibsonville, N.C., where Christmas tree grower Bryce Wagoner brings his trees from Sparta to sell. The Appalachian Mountains of North Carolina are home to some of the most Christmas tree farms in the country. (Photo: Sam Kim)</figcaption>
                </figure>
                <div class='line-break' />
            </div>
            <div class='content'><p><b>GIBSONVILLE, N.C.</b> — When choosing a Christmas tree, most people look for firm needles, perfect height and a fresh, wintry scent. The Christmas tree lobby would like to add immigration reform to that list.</p>
                <p>Behind the <a href="www.realchristmastrees.org/dnn/News-Media/Industry-Statistics/Consumer-Survey">27 million pines, firs and spruces</a> that will adorn American homes next week is the little-known industry of Christmas tree farming. Like most sectors of American agriculture, it demands intensive seasonal labor that growers have been increasingly unable to fill with Americans alone, and the intended remedy—a visa program known as H-2A that hires migrant workers on a temporary basis—remains far from addressing the problem, growers say.</p>
                <p>“It’s very, very clear that we have labor shortages in pretty much every branch of ag,” said Craig Regelbrugge, senior vice president of industry advocacy and research at the lobbying firm AmericanHort, whose clients include the National Christmas Tree Association. “You can talk to any grower.”</p>
                <p>Though no federal agency collects data on labor shortages, in a 2017 survey by the <a href="www.cfbf.us/wp-content/uploads/2017/10/CFBF-Ag-Labor-Availability-Report-2017.pdf">California Farm Bureau Federation</a>, 55 percent of farmers and ranchers reported not hiring enough workers. That figure jumped to nearly 70 percent for farmers depending on seasonal labor.</p>
                <p>Despite the shortages, fewer than 3 percent reported hiring guest workers. Most other farmers chose to go short-handed rather than to apply for the H-2A program, citing the costs and paperwork involved.</p>

                <h4>What Is the H-2A?</h4>
                <p>Established in 1952 by the Immigration and Nationality Act, the H-2 program was meant as an <a href="https://cis.org/Guestworker-Programs">alternative</a> to the massive <a href="https://www.govinfo.gov/content/pkg/STATUTE-65/pdf/STATUTE-65.pdf">bracero programs</a>, which exclusively brought millions of Mexican farmworkers to the wartime United States. After union backlash ended the program, the H-2A took its place.</p>
                <p>Under the H-2A, farmers who expect a labor shortage and could not hire enough willing Americans can apply to bring in temporary help from other countries. These guest workers, mostly from Mexico, typically stay in the U.S. for about nine months before returning to their home countries to reapply.</p>
                <p>“We’ve been able to use the H-2A workers rather than immigrant, undocumented workers,” said Doug Hundley, spokesman for the National Christmas Tree Association, which represents Christmas tree growers. “We use undocumented workers, no question, but we use a lot less of them… None of these guys try to jump and disappear into the country. They’re some of the best workers you could ever use.”</p>
                <p>Unlike California, North Carolina—the <a href="https://www.nass.usda.gov/Publications/AgCensus/2012/Full_Report/Volume_1,_Chapter_2_US_State_Level/st99_2_035_035.pdf">second-largest</a> producer of Christmas trees in the country—makes <a href="https://www.foreignlaborcert.doleta.gov/pdf/PerformanceData/2018/H-2A_Selected_Statistics_FY2018_Q3.pdf">extensive use</a> of the work visa program, according to the Labor Department. Christmas tree farms employ many such workers in general because much of the labor need is seasonal, and not just for the harvest.</p>
                <p>Eric Cobb, an NCTA member and an owner of a Christmas tree farm in Gibsonville, explained that the trees need regular pruning to guide them into the familiar, symmetric cones that people will buy. The pruning is usually done in the summer, when branches <a href="https://journals.ashs.org/hortsci/view/journals/hortsci/39/7/article-p1644.xml">begin to sprout</a>—and when most workers are busy tending other crops.</p>
                <p>“Most people who plant trees here have local guys like Dusty, and they work trees when they’re not working their regular jobs,” Cobb said, pointing to one of his part-timers. “But in the summertime, up in the mountains, they get a shortage.”</p>
                <figure class='fig'>
                    <img
                        src={require("../images/posts/christmas-trees-2018/cobb.png").default}
                        alt={"Christmas tree grower Eric Cobb, left, with one of his workers."}
                        style={{ width: "100%" }}
                    />
                    <figcaption>Christmas tree grower Eric Cobb, left, with one of his workers inside the barn at his Gibsonville Christmas tree farm. (Photo: Sam Kim)</figcaption>
                </figure>
                <p>Despite the help they receive from guest workers, Christmas tree farmers are unhappy with the H-2A system.</p>
                <p>“It’s an expensive, complicated, bureaucratic program,” said Regelbrugge, who has <a href="https://soprweb.senate.gov/index.cfm?event=getFilingDetails&filingID=25765BE9-813D-475E-AEBB-102D68353B90&filingTypeID=2">lobbied</a> Washington on agricultural issues, including migrant workers, on behalf of NCTA and other groups. “For most farmers, it’s the last resort.”</p>
                <p>Regelbrugge explained that the H-2A program sets a fixed pay for workers, called the <a href="https://www.foreignlaborcert.doleta.gov/pdf/AEWR/AEWR_Map_2018.pdf">adverse effect wage rate</a>, for each state. In North Carolina, that rate is $11.46 an hour. In comparison, the <a href="https://www.labor.nc.gov/workplace-rights/employee-rights-regarding-time-worked-and-wages-earned/minimum-wage-nc">state minimum wage</a> is only $7.25. The policy has long been a thorn in the agricultural industry, and Regelbrugge described it as a “contrivance of the worker advocates” to curtail H-2A.</p>
                <p>“But worker pay is such a controversial topic,” he said. “If a farmer goes up to Congress and says, ‘I want to pay my worker less,’ there’s no sympathy for that.”</p>
                <p>According to NCTA spokesman Hundley, the relatively high pay is only part of Christmas tree growers’ grudges.</p>
                <p>To use the H-2A program, farmers must also <a href="www.globalworkers.org/sites/default/files/feescostb.pdf">pay</a> for their workers’ daily commute, as well as transportation to and from the border. They must also provide workers with free meals, housing and utilities, which are subject to <a href="https://files.nc.gov/ncdol/ash/osh-example-programs/echklist.pdf">yearly inspection</a>.</p>
                <figure class='fig'>
                    <img
                        src={require("../images/posts/christmas-trees-2018/xmas-trees.png").default}
                        alt={"Christmas trees ready for harvest at Cobb’s Choose-and-Cut Christmas Trees, Gibsonville."}
                        style={{ width: "100%" }}
                    />
                    <figcaption>Hundreds of Christmas trees ready for harvest at Cobb’s Choose-and-Cut Christmas Trees, Gibsonville. Though customers cut their own trees here, the farm still needs workers to prune each conifer, left, into the familiar conical shape, right. (Photo: Sam Kim)</figcaption>
                </figure>

                <h4>A Christmas List for Congress</h4>
                <p>According to <a href="https://soprweb.senate.gov/index.cfm?event=getFilingDetails&filingID=25765BE9-813D-475E-AEBB-102D68353B90&filingTypeID=2">Senate lobbying disclosures</a>, the NCTA has retained AmericanHort, then called the American Nursery and Landscape Association, as its lobbying firm since 2007. Though the firm has a political action committee (nicknamed “<a href="https://www.americanhort.org/page/plantpac">PlantPAC</a>”), it does not appear to wield much clout on Capitol Hill—it spend just <a href="https://www.fec.gov/data/committee/C00022988/">$13,500</a> this election cycle, compared to the millions of dollars that flow from <a href="https://www.opensecrets.org/pacs/toppacs.php">other PACs</a> to candidates.</p>
                <p>Instead of directly contributing to campaigns, AmericanHort has spent up to $1,086,500 lobbying Congress and various federal agencies, mostly the Agriculture and Labor Departments, in the past decade.</p>
                <p>We looked through the 47 lobbyist reports the firm has filed since 2007. And, though the agricultural industry’s interests seem to shift from year to year, two items have never left its wish list: the farm bill, a legislative behemoth that declares the United States’ agricultural policy every five years, and the guest worker program.</p>
                <p>“Part of our public policy agenda is to make H-2A better,” Regelbrugge said.</p>
                <p>One proposed way is replacing the current program with a new visa, dubbed H-2C, that would bring in year-round migrant workers and let seasonal workers stay longer in the United States. The latest attempt, the <a href="https://www.congress.gov/bill/115th-congress/house-bill/4760">Securing America’s Future Act</a>, proposed this change, but it also included asylum restrictions, tough immigration enforcement, an end to the <a href="https://www.nytimes.com/2017/11/01/us/diversity-visa-lottery.html">green-card lottery</a> and funding for a wall along the southern border.</p>
                <p>Regelbrugge, who lobbied on the bill, called the guest worker reforms “<a href="https://www.growingproduce.com/citrus/industry-experts-target-labor-growing-industry-pain-points/">attractive</a>” but cited “<a href="https://hortknowledgecenter.org/Article-Detail?DocumentID=2855">deep concerns</a>” with the rest of the proposals. Supported by <a href="https://twitter.com/realdonaldtrump/status/1011952266268545024?lang=en">President Donald Trump</a> and opposed by <a href="https://www.democraticwhip.gov/content/daily-whip-thursday-june-21-2018">Democrats</a> and <a href="https://www.motherjones.com/politics/2017/11/the-gop-wants-to-bring-in-more-migrant-guest-workers-but-for-much-lower-pay/">immigration advocates</a>, the bill ultimately failed in the House.</p>
                <p>No matter the way to do it, Regelbrugge said, labor reform is critical for Christmas tree growers—without enough labor, the production could move to Canada. And he hoped Trump would support their efforts.</p>
                <p>“There is a political irony that farmers, many of whom are conservative and supported President Trump, are bewildered on this issue,” Regelbrugge said. “They hoped to see progress under this administration, instead of [moving] backward on a bad situation getting worse.”</p>
                <div class='line-break' />

                <div class='refs'>
                    <h4>Sources</h4>
                    <p>Interview with Bryan Wagoner, Christmas tree farmer based in Sparta, Dec. 14, 2018.</p>
                    <p>National Christmas Tree Association’s <a href="www.realchristmastrees.org/dnn/News-Media/Industry-Statistics/Consumer-Survey">2017 Christmas season consumer survey</a>, conducted by Nielsen/Harris Poll from Jan. 9 to 11, 2018.</p>
                    <p>Phone interview with Craig Regelbrugge, senior vice president of industry advocacy and research at AmericanHort, Dec. 14, 2018.</p>
                    <p><a href="www.cfbf.us/wp-content/uploads/2017/10/CFBF-Ag-Labor-Availability-Report-2017.pdf">Agricultural labor availability survey results</a>, conducted by the California Farm Bureau Federation, released October 2017.</p>
                    <p>Center for Immigration Studies, “<a href="https://cis.org/Guestworker-Programs">Guestworker programs: Lessons from the past and warnings for the future</a>,” March 1, 2004.</p>
                    <p>Public Law 78 of the 82nd Congress, “<a href="https://www.govinfo.gov/content/pkg/STATUTE-65/pdf/STATUTE-65.pdf">An act to amend the Agricultural Act of 1949</a>,” enacted July 12, 1951.</p>
                    <p>Phone interview with Doug Hundley, spokesman for the National Christmas Tree Association, Dec. 14, 2018.</p>
                    <p>Department of Agriculture, <a href="https://www.nass.usda.gov/Publications/AgCensus/2012/Full_Report/Volume_1,_Chapter_1_US/usv1.pdf">2012 Census of Agriculture</a>, published May 2014.</p>
                    <p>Employment and Training Administration’s Office of Foreign Labor Certification, <a href="https://www.foreignlaborcert.doleta.gov/pdf/PerformanceData/2018/H-2A_Selected_Statistics_FY2018_Q3.pdf">H-2A temporary agricultural labor certification program statistics for fiscal year 2018</a>, June 30, 2018.</p>
                    <p>U.S. Senate Lobbying Disclosure Act database, <a href="https://soprweb.senate.gov/index.cfm?event=getFilingDetails&filingID=25765BE9-813D-475E-AEBB-102D68353B90&filingTypeID=2">American Nursery and Landscape Association’s registration amendment</a>, filed Aug. 9, 2007.</p>
                    <p>ETA’s Office of Foreign Labor Certification, <a href="https://www.foreignlaborcert.doleta.gov/pdf/AEWR/AEWR_Map_2018.pdf">adverse effect wage rates for fiscal year 2018</a>, accessed Dec. 17, 2018.</p>
                    <p>North Carolina Department of Labor, “<a href="https://www.labor.nc.gov/workplace-rights/employee-rights-regarding-time-worked-and-wages-earned/minimum-wage-nc">Minimum wage in N.C.</a>,” accessed Dec. 17, 2018.</p>
                    <p>Global Workers Justice Alliance, “<a href="www.globalworkers.org/sites/default/files/feescostb.pdf">H-2A fees and costs: At a glance</a>,” accessed Dec. 17, 2018. </p>
                    <p>N.C. Labor Department’s Agricultural Safety and Health Bureau, <a href="https://files.nc.gov/ncdol/ash/osh-example-programs/echklist.pdf">migrant housing inspection checklist</a>, accessed Dec. 16, 2018.</p>
                    <p>Interview with Eric Cobb, owner of <a href="http://www.nc-chooseandcut.com/gallery_show.php?ID=5">Cobb’s Choose & Cut Christmas Trees</a> in Gibsonville, N.C., Dec. 14, 2018.</p>
                    <p>L. Eric Hinesley and Scott A. Derby of North Carolina State University, “<a href="https://journals.ashs.org/hortsci/view/journals/hortsci/39/7/article-p1644.xml">Growth of Fraser fir Christmas trees in response to annual shearing</a>,” published in the December 2004 issue of HortScience.</p>
                    <p>AmericanHort, “<a href="https://www.americanhort.org/page/plantpac">PlantPAC</a>,” accessed Dec. 18, 2018.</p>
                    <p>Federal Election Commission, <a href="https://www.fec.gov/data/committee/C00022988/">AmericanHort PAC’s financial filings for 2017–18</a>, accessed Dec. 15, 2018.</p>
                    <p>OpenSecrets, “<a href="https://www.opensecrets.org/pacs/toppacs.php">Top 20 PAC contributors to candidates, 2017–2018</a>,” accessed Dec. 15, 2018.</p>
                    <p>H.R. 4760 of the 115th Congress, <a href="https://www.congress.gov/bill/115th-congress/house-bill/4760/actions">Securing America’s Future Act of 2018</a>, failed in the House June 21, 2018.</p>
                    <p>“<a href="https://www.nytimes.com/2017/11/01/us/diversity-visa-lottery.html">Diversity visa lottery: Inside the program that admitted a terror suspect</a>,” The New York Times, Nov. 1, 2017.</p>
                    <p>Craig Regelbrugge, “<a href="https://hortknowledgecenter.org/Article-Detail?DocumentID=2855">Will immigration enforcement bill ride on omnibus?</a>” AmericanHort Knowledge Center, March 13, 2018.</p>
                    <p>“<a href="https://www.growingproduce.com/citrus/industry-experts-target-labor-growing-industry-pain-points/">Experts target labor and other growing industry pain points</a>,” Growing Produce, May 7, 2018.</p>
                    <p>President Donald Trump, <a href="https://twitter.com/realdonaldtrump/status/1011952266268545024?lang=en">tweet</a>, June 27, 2018.</p>
                    <p>Democratic Whip Steny Hoyer, “<a href="https://www.democraticwhip.gov/content/daily-whip-thursday-june-21-2018">The Daily Whip</a>,” June 21, 2018.</p>
                    <p>“<a href="https://www.motherjones.com/politics/2017/11/the-gop-wants-to-bring-in-more-migrant-guest-workers-but-for-much-lower-pay/">The GOP wants to bring in more migrant guest workers—but for much lower pay</a>,” Mother Jones, Nov. 1, 2017.</p>
                    <div class='line-break' />

                    <h4>Missed Connections</h4>
                    <p>Justin Darisse, Agricultural Workforce Coalition spokesman and vice president of communications for the National Council of Farmer Cooperatives, did not respond to an interview request about the current state of American agricultural industries.</p>
                    <p>John Frampton, forestry and environmental resources professor at North Carolina State University who studies Christmas tree genetics, did not respond to an interview request about Christmas tree farming.</p>
                    <p>Jennifer Greene, executive director of the North Carolina Christmas Tree Association, could not be reached for comment about immigration laws and labor shortages.</p>
                    <p>The Eastern North Carolina Christmas Tree Growers Association, which represents farmers in the Piedmont and coastal regions, did not respond to an interview request.</p>
                </div>
            </div>
        </>)
    }
}

export default ChristmasTrees2018
