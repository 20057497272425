import React from "react"

import Ref from "../components/Writings/Ref"
import Fig from "../components/Writings/Fig"

class ThreeDomains2019 extends React.Component {
    render() {
        return (<>
            <div className='preface'>
                <p><b>Professor:</b> Amy Schmid</p>
                <p className='abstract'><b>Abstract:</b> Woese stunned the microbiological world by refuting the accepted prokaryote–eukaryote dichotomy and redrawing the tree of life to include a third top-level group: the Archaea. However, Woese and his colleagues were limited by the computational tools available to them at the time, and the reliance on culture-dependent methods of species discovery meant many difficult-to-culture archaea went unstudied. Since the acceptance of the three-domain model, the rapidly expanding field of computational biology and bioinformatics has seen the rise of culture-independent, high-throughput techniques that use genomic signatures to identify species, as well as of powerful phylogenetic algorithms that better accommodate large data and model evolutionary problems. In light of recent discoveries, in this review, we argue that the development of these techniques has allowed the thorough rejection of the three-domain model in favor of a two-domain model that places eukaryotes within the Archaea.</p>
                <div className='line-break' />
            </div>

            <div className='content'>
                <h4 id='s1'>1. Introduction</h4>
                <p className='quote'>“The time will come I believe, though I shall not live to see it, when we shall have very fairly true genealogical trees of each great kingdom of nature.”</p>
                <p className='quote-author'>— Charles Darwin, 1857</p>
                <p>By the 20th century, microbiology and cell biology had progressed to the point where scientists could place all life on Earth, extinct and extant, in one of two groups: prokaryotes and eukaryotes.<Ref n='1' /> The distinction rested on purely physiological characteristics. By definition, prokaryotes had no membrane-bound organelles, reproduced by binary fission and contained peptidoglycan in their cell walls.<Ref n='2' /> The two groups had been observed to be have such fundamentally different characteristics that they were assumed to differ fundamentally in their evolutionary histories as well. That is, the prokaryote–eukaryote divide was hypothesized to be the first bifurcation in the phylogenetic tree of all life, starting from the last common universal ancestor (LUCA).<Ref n='3' /></p>
                <p>The discovery of methanogens, and later of extreme thermophiles and halophiles, challenged this dichotomy. Novel methods of comparing life—namely, sequencing genes that encode 16S ribosomal RNA (rRNA), crucial in protein synthesis and highly conserved across species—revealed these prokaryotes, despite their apparent phenotypic similarities, to be no more related to other bacterial organisms than to eukaryotes.<Ref n='4' /> Based on this discovery, microbiologist Carl Woese and his colleagues proposed a new, three-domain model: eukaryotes, bacteria and the third group of archaea, which were poorly understood at the time, with few discovered species. Though Woese <i>et al.</i> did find an evolutionary relationship between archaea and eukaryotes—an instantaneous trifurcation event would be impossibly rare—they declined to give that relationship a taxonomic recognition, saying the two domains were so different and had diverged so early that “little would be gained by defining a taxon that encompasses both.”<Ref n='5' /></p>
                <p>Now, the time has come to recognize that relationship. To be sure, the three-domain system had been challenged before. The discovery of a unique general ribosomal structure in the archaeal orders Sulfolobales and Thermoproteales led researchers to propose a fourth domain, consisting entirely of these sulfur-dependent “eocytes” and coequal in taxonomy with Woese’s other three domains of life.<Ref n='6' /> It was later proposed, after the discovery and identification of more archaeal species, that these eocytes actually belonged to a different phylum of archaea, the Crenarchaeota, distinct from the then-known methanogens and halophiles, which belonged to the phylum Euryarchaeota.<Ref n='5' /> However, Lake <i>et al.</i>’s ultrastructural concerns were valid. Ribosomal structure is incredibly diverse across species, but usually only in the ribosomal proteins’ nonglobular extensions, which are thought to play a stabilizing role in rRNA folding and ribosome biogenesis.<Ref n='7' /><sup>–</sup><Ref n='8' /> Any major divergence in the globular structure, like the ones shown in <Fig n='1' /> that were observed by Lake <i>et al.</i>, would have been highly unusual and potentially domain-differentiating. With limited understanding of archaea, few discovered species, and primitive molecular phylogenetic techniques, Lake <i>et al.</i> could not have known that their discovery necessitated a reduction, not expansion, of the three-domain model.</p>
                <figure className='fig' id='fig1'>
                    <img
                        src={require("../images/posts/three-domains-2019/fig1.png").default}
                        alt={"Figure 1."}
                        style={{ width: "100%" }}
                    />
                    <figcaption><b>Figure 1</b> | <b>a</b>, The structural differences of the large (top) and small (bottom) ribosomal subunits, as identified by Lake <i>et al.</i> Diagonal shading indicates features found only in eukaryotes; cross-hatching indicates features found only in eukaryotes and eocytes. <b>b</b>, The unrooted dendrogram showing the proposed evolutionary relationships of the four taxa, as inferred from findings in <Fig n='1' l='a' />. Hypothesized transitional structures are also shown. Though flawed in their inferences—our review argues that an archaeal taxon excluding eocytes and eukaryotes is paraphyletic—the researchers were prescient in realizing that eocytes and eukaryotes were more closely related than had been previously thought.<Ref n='6' /></figcaption>
                </figure>
                <p>Woese and his team were limited by the tools of their time and undersampling of microbial diversity. Using improved and more intensive sampling methods, as well as more advanced computational methods based on next-generation sequencing and phylogenetic techniques, far more archaea have been discovered, sequenced and characterized. And the recent discovery of one such group of archaea—the Lokiarchaeota, which has genomic and physiological similarity to eukaryotes<Ref n='9' />—has uprooted the three-domain tree in favor of the eocyte hypothesis, which proposes that eukaryotes arose from within the Archaea domain, possibly as a sister clade to Crenarchaeota (eocytes) or its superphylum.<Ref n='10' /> In this review, I argue that, based on the experimental and computational techniques developed since the discovery of the archaea, this two-domain model is more convincing than the established three-domain counterpart.</p>

                <h4 id='s2'>2. New Discovery Methods</h4>
                <p>Much of the recent advances in the understanding of archaea have come from simply studying more archaea. Traditional methods of discovering microorganisms have involved culturing a sample in artificial media and isolating the species in pure culture, or at least defined cocultures.<Ref n='11' /> Such was the case for Woese and George Fox in their initial discovery of the archaeal domain. The four methanogenic species used in the study came from cultured samples, and, lacking many of the molecular biology techniques that are now indispensable in the field—namely polymerase chain reaction (PCR) and Sanger sequencing—the 16S rRNA sequencing involved culturing the microbes in radioactive phosphate-32.<Ref n='4' /><sup>,</sup><Ref n='12' /> The resulting radiolabeled rRNA was extracted, digested and separated with gel electrophoresis, after which the bands were gel-purified and digested further into fragments of 5–15 nt. The sequences of these oligonucleotides were then decoded using RNA fingerprinting—a laboring, time-intensive and now-obsolete technique.<Ref n='12' /><sup>–</sup><Ref n='13' /></p>
                <p>Though useful for certain microbes—typically aerobic, mesophilic, free-living bacteria—culture-dependent approaches are highly flawed one when looking for novel species.<Ref n='14' /> Only about 1% of bacteria on Earth are estimated to be readily culturable in vitro, for a myriad of known and unknown reasons: unusual nutritional or metabolic requirements, intolerance to an otherwise innocuous component of the medium, extreme growth conditions, lack of intraspecific signaling, deprivation of interspecific relationships, competition from nearby colonies, low natural abundance, etc.<Ref n='15' /> Culture-dependent methods of characterizing microbial diversity also heavily suffer from sampling bias, where otherwise scarce species in the natural environment can thrive and outcompete high-abundance species—oligotrophs in low-nutrient habitats, cells in starvation or dormant states, biofilm-forming microbes, etc.—in nutrient-rich culture.<Ref n='16' /></p>
                <p>The approach of isolating and identifying species from an environmental sample, involving dozens of pure cultures and a battery of biochemical tests to discard the known ones, is also prohibitively expensive in terms of time and resources. For example, the only pure cultivation of an Asgard archaeon so far—a Lokiarchaeota-related species dubbed <i>Candidatus Prometheoarchaeum syntrophicum</i> strain MK-D1—required an almost 6-year operation of a continuous-flow bioreactor and 12 total years of effort, according to the unpublished study.<Ref n='17' /> This may explain why archaea were first thought to be all extremophiles—in a self-fulfilling prophecy, scientists sampled extreme environments that were expected to have low species diversity, such as volcanoes, hot springs, acid mine drainage, salt lakes, ocean floors and the like.<Ref n='18' /> However, new approaches have allowed the discovery of many more mesophilic archaea, including that of the Thaumarchaeota, originally misclassified as “mesophilic crenarchaeota,” and the Thorarchaeota, of the same superphylum as the Lokiarchaeota and discovered in the blackwater rivers of North Carolina, United States—not exactly an extreme habitat.<Ref n='19' /><sup>–</sup><Ref n='20' /></p>
                <p>Two relatively new, high-throughput approaches to lineage discovery have allowed the identification of most known archaea today. One is metagenomics, where a complex environmental mixture of genomes and genomic fragments, belonging to many different species, is totally sequenced, cleaned, assembled when possible into contiguous consensus sequences (contigs), and compared against an authoritative sequence database.<Ref n='21' /> A similar method, called community profiling, amplifies by polymerase chain reaction (PCR) then sequences a specific, conserved region of the genome, such as the 16S rRNA. These results are then used to compare the differences in the less conserved hypervariable regions.<Ref n='20' /> The other method is single-cell genomic sequencing, where desired cells are singly isolated using fluorescence tagging or microfluidic sorting or other ways.<Ref n='22' /> The genomes of these cells are then wholly amplified through multiple-displacement amplification, where random primers are annealed throughout the genome and a high-fidelity DNA polymerase creates replicated oligonucleotides that can be assembled later.<Ref n='23' /> The two high-throughput strategies outlined here allow researchers to obtain genomic information from uncommon or unculturable microorganisms—including archaea—and, as a consequence, to estimate species abundance and richness more accurately.</p>
                <p>Metagenomics and single-cell genomics have been instrumental in the identification of entire lineages of archaea, including the DPANN (Diapherotrites, Parvarchaeota, Aenigmarchaeota, Nanohaloarchaeota, Nanoarchaeota, among others) and TACK (Thaumarchaeota, Aigarchaeota, Crenarchaeota, Korarchaeota) superphyla.<Ref n='24' /><sup>–</sup><Ref n='25' /> The improved understanding of archaeal relationships has allowed the construction of new phylogenetic trees among the archaea, including that which places eukaryotes in the Asgard superphylum of recently discovered Lokiarchaeota and Thorarchaeota.</p>

                <h4 id='s3'>3. New Phylogenetic Methods</h4>
                <p>The high-throughput, culture-independent strategies outlined above would have been impractical or impossible without the novel computational and phylogenetic tools that were not yet available to Woese in 1977. Existing methods included maximum parsimony (MP), whose trees minimize the number of state changes (e.g., mutations) needed to achieve the observed data, and maximum likelihood (ML), which maximizes the probability of the observed data given a certain tree.<Ref n='26' /><sup>–</sup><Ref n='27' /> Both techniques remain widely used in phylogenetics today.<Ref n='28' /></p>
                <p>However, two major phylogenetic methods were not known to the field when Woese <i>et al.</i> declared the three-domain model: Bayesian inference (BI) and neighbor joining (NJ). BI applies the opposite logic as that of ML, maximizing the posterior probability—that is, the probability of seeing a certain tree given the observed data.<Ref n='29' /> Though BI’s power nears that of ML, BI is much more efficient; though both methods must navigate the entire tree space in theory, BI avoids ML’s severe inefficiency by using Markov-chain Monte Carlo (MCMC), a stochastic heuristic that allows approximating the posterior probabilities.<Ref n='30' /><sup>–</sup><Ref n='31' /> On the other hand, the popularity and use of NJ—a hierarchical clustering method that infers phylogenetic relationships based on distance metrics, or measures of similarity, between sequences—has waned in recent years.<Ref n='32' /><sup>–</sup><Ref n='35' /> NJ’s main advantage of speed, especially when analyzing large data sets and when bootstrap resampling, had made it desirable in situations where more powerful and robust methods such as ML were computationally impractical.<Ref n='36' /> But NJ has largely been outpaced by the advent of BI, development of more efficient algorithms for ML, and vast advances in computer hardware and high-performance computing.<Ref n='31' /><sup>,</sup><Ref n='37' /><sup>–</sup><Ref n='38' /></p>
                <p>Also unavailable to Woese and his contemporaries in 1977 were the dominant resampling techniques used to measure confidence in reconstructed clades today. Jackknifing—adapted for phylogenetics in 1985—takes subsets of the data set and determines the fraction of replicate trees, built with the subsets, that contain the same local topology as the original.<Ref n='39' /> Bootstrapping—also developed in 1985—is similar to jackknifing except, instead of subsetting, it resamples the original data set with replacement.<Ref n='40' /> Woese’s tree included no such measures of support.<Ref n='41' /></p>
                <p>These advances in phylogenetic techniques have allowed more robust and accurate reconstructions of phylogenetic trees, whether by developing algorithms that better model evolutionary rates, or by optimizing existing methods to handle very large data sets.<Ref n='37' /><sup>,</sup><Ref n='42' /> When Woese and Fox first proposed the archaeal domain, they used a distance matrix-based algorithm to support their claim, shown in <Fig n='2' l='a' />, admitting that “analyses of sequence data today are far from optimal.”<Ref n='4' /><sup>,</sup><Ref n='43' /> The new tree-building algorithms have been instrumental in quite literally redrawing the tree of life.<Ref n='9' /> It is difficult to imagine that, with the tools of their time, Woese and his colleagues could have made a phylogenetic argument for the two-domain model.</p>
                <figure className='fig' id='fig2'>
                    <img
                        src={require("../images/posts/three-domains-2019/fig2.png").default}
                        alt={"Figure 2."}
                        style={{ width: "100%" }}
                    />
                    <figcaption><b>Figure 2</b> | <b>a</b>, Woese and Fox’s universal phylogenetic tree of the three domains, based on 16S rRNA and built using a distance matrix-based least squares algorithm. Support values, if they were calculated, were not included in publication.<Ref n='41' /> <b>b</b>, Spang <i>et al.</i>’s phylogenetic tree of select archaea (gray/red) and eukaryotes (blue), based on a metagenomic approach and built using BI. Numbers above and below branches indicate Bayesian posterior probability and ML bootstrap support values, respectively.<Ref n='9' /></figcaption>
                </figure>

                <h4 id='s4'>4. Two- vs. Three-Domain Model</h4>
                <p>For decades, evidence had been emerging that the universal phylogenetic tree was flawed, starting with Lake <i>et al.</i>’s report that eukaryotes and Crenarchaeota, or eocytes, had similar ribosomal structures that could not be explained by the three-domain model.<Ref n='6' /> Lake later gave a computational argument for his claim using 16S rRNA sequencing, which showed eukaryotic nuclei to originate from the Crenarchaeota.<Ref n='44' /> As molecular biology methods continued to be developed and become more sophisticated, Woese’s concept of comparing 16S rRNA sequences gave way to comparing repertoires of dozens of genes known to be conserved, such as ribosomal proteins, RNA polymerases, elongation factors, transfer RNA ligases and ATP-binding cassettes.<Ref n='45' /><sup>–</sup><Ref n='48' /> These studies and more arrived at phylogenetic trees showing similar results—that eukaryotes originated from within or as a sister clade the Crenarchaeota, or as a sister clade to the TACK superphylum. These findings follow at least the broadest interpretation of the eocyte hypothesis—that the first eukaryotic common ancestor (FECA) evolved from archaea. The most conclusive evidence came with the discovery of Asgard archaea. They were found not only to be the closest prokaryotic relatives to eukaryotes discovered so far, but also to contain many genes encoding so-called eukaryote-specific proteins (ESPs), including those involved in membrane deformation, cell shape formation processes, and components of membrane-trafficking machineries.<Ref n='9' /><sup>,</sup><Ref n='49' /> The discovery and analyses of other Asgard archaea have confirmed previous conclusions and bolstered the case for the two-domain model.<Ref n='50' /></p>
                <p>The largest criticism of the phylogenetic analyses of Asgard archaea has been the possibility of eukaryotic contamination, which could account for the presence of ESP genes;<Ref n='51' /> such claims were put to rest by Spang <i>et al.</i>’s clarifications, and as the independent discoveries of other Asgard species found similar phylogenies.<Ref n='20' /><sup>,</sup><Ref n='52' /><sup>–</sup><Ref n='53' /></p>
                <p>Other arguments against the two-domain model claim that an archaeal ancestry to eukaryotes is biologically infeasible. Patrick Forterre, a prominent skeptic of the significance of Lokiarchaeota, raised many, apparently sound concerns, but they are grounded in faulty assumptions—namely that LUCA was a mesophile and the last common archaeal ancestor (LACA) was a hyperthermophile.<Ref n='54' /> The presence of reverse gyrase—usually found in thermophiles—as well as the need for enthalpy on a primordial, enzyme-free Earth to drive biochemical reactions suggest that LUCA was a thermophile.<Ref n='55' /><sup>–</sup><Ref n='56' /> And the assumption that LACA must have occupied high-temperature niches is based on the popular misconception that most extant archaea are extremophilic.<Ref n='18' /></p>
                <p>One major problem with an archaeal origin for FECA is the lipid divide—despite phylogenetic findings, bacteria and eukaryotes have the same membrane biochemistry, which is different from that of archaea.<Ref n='57' /> This remains an open problem in the field and is outside the scope of this review.</p>

                <h4 id='s5'>5. Conclusion</h4>
                <p>Woese’s use of 16S rRNA for phylogeny, and his subsequent declaration of the three-domain model, were breakthroughs in evolutionary biology and microbiology. However, later advances in discovery and phylogenetic methods have made clear that a two-domain model, placing eukaryotes within the Archaea, is more convincing than the established three-domain counterpart. Our understanding of prokaryotic phylogeny has only grown with discovering more prokaryotes, and the identification of most known archaea today owes itself to culture-independent, high-throughput methods such as community profiling and single-cell genomic sequencing. And the massive data sets generated by such approaches have only been able to be analyzed with improvements in existing phylogenetic algorithms, such as ML, or the development of new ones, such as BI, which remain powerful while still being computationally feasible. The culmination of these advancements was most pronounced in 2015, when Spang <i>et al.</i> used a metagenomic approach to detect the presence of a novel group of archaea—the Lokiarchaeota—whose close phylogenetic relationship with eukaryotes was determined with confidence through BI.<Ref n='9' /> The recent developments in experimental and computational techniques have allowed a strong case for the two-domain model and, therefore, the rejection of the three-domain system.</p>
            </div>
            <div className='line-break' />

            <div className='refs'>
                <h4>References</h4>
                <ol>
                    <li id='ref1'>Cooper, G. M. <i>The Cell: A Molecular Approach</i>. 2nd ed., Sinauer Associates, Sunderland, MA (2000).</li>
                    <li id='ref2'>Stanier, R. Y., and Van Niel, C. B. The concept of a bacterium. <i>Arch. Mikrobiol.</i> 42: 17–35 (1962).</li>
                    <li id='ref3'>Pace, N. R., Sapp, J., and Goldenfeld, N. Phylogeny and beyond: Scientific, historical, and conceptual significance of the first tree of life. <i>Proc. Natl. Acad. Sci. U.S.A.</i> 109: 1011 (2012).</li>
                    <li id='ref4'>Woese, C. R., and Fox, G. E. Phylogenetic structure of the prokaryotic domain: The primary kingdoms. <i>Proc. Natl. Acad. Sci. U.S.A.</i> 74: 5088 (1977).</li>
                    <li id='ref5'>Woese, C. R., Kandler, O., and Wheelis, M. L. Towards a natural system of organisms: Proposal for the domains Archaea, Bacteria, and Eucarya. <i>Proc. Natl. Acad. Sci. U.S.A.</i> 87: 4576 (1990).</li>
                    <li id='ref6'>Lake, J. A., Henderson, E., Oakes, M., and Clark, M. W. Eocytes: A new ribosome structure indicates a kingdom with a close relationship to eukaryotes. <i>Proc. Natl. Acad. Sci. U.S.A.</i> 81: 3786 (1984).</li>
                    <li id='ref7'>Melnikov, S., Manakongtreecheep, K., and Söll, D. Revising the structural diversity of ribosomal proteins across the three domains of life. <i>Mol. Biol. Evol.</i> 35: 1588–1598 (2018).</li>
                    <li id='ref8'>Timsit, Y., Acosta, Z., Allemand, F., Chiaruttini, C., and Springer, M. The role of disordered ribosomal protein extensions in the early steps of eubacterial 50 S ribosomal subunit assembly. <i>Int. J. Mol. Sci.</i> 10: 817–834 (2009).</li>
                    <li id='ref9'>Spang, A. <i>et al.</i> Complex archaea that bridge the gap between prokaryotes and eukaryotes. <i>Nature</i> 521: 173 (2015).</li>
                    <li id='ref10'>Archibald, J. M. The eocyte hypothesis and the origin of eukaryotic cells. <i>Proc. Natl. Acad. Sci. U.S.A.</i> 105: 20049 (2008).</li>
                    <li id='ref11'>Amann, R. I., Ludwig, W., and Schleifer, K. H. Phylogenetic identification and in situ detection of individual microbial cells without cultivation. <i>Microbiol. Rev.</i> 59: 143 (1995).</li>
                    <li id='ref12'>Luehrsen, K. R. Remembering Carl Woese. <i>RNA Biol.</i> 11: 217–219 (2014).</li>
                    <li id='ref13'>Ragan, M. A., Bernard, G., and Chan, C. X. Molecular phylogenetics before sequences: Oligonucleotide catalogs as <i>k</i>-mer spectra. <i>RNA Biol.</i> 11: 176–185 (2014).</li>
                    <li id='ref14'>Hugenholtz, P. Exploring prokaryotic diversity in the genomic era. <i>Genome Biol.</i> 3: reviews0003.1 (2002).</li>
                    <li id='ref15'>Vartoukian, S. R., Palmer, R. M., and Wade, W. G. Strategies for culture of “unculturable” bacteria. <i>FEMS Microbiol. Lett.</i> 309: 1–7 (2010).</li>
                    <li id='ref16'>Overmann, J., Abt, B., and Sikorski, J. Present and future of culturing bacteria. <i>Annu. Rev. Microbiol.</i> 71: 711–730 (2017).</li>
                    <li id='ref17'>Imachi, H. <i>et al.</i> Isolation of an archaeon at the prokaryote–eukaryote interface. <i>bioRxiv</i> 726976 (2019).</li>
                    <li id='ref18'>Eme, L., and Doolittle, W. F. Archaea. <i>Curr. Biol.</i> 25: R851–R855 (2015).</li>
                    <li id='ref19'>Brochier-Armanet, C., Boussau, B., Gribaldo, S., and Forterre, P. Mesophilic crenarchaeota: Proposal for a third archaeal phylum, the Thaumarchaeota. <i>Nat. Rev. Microbiol.</i> 6: 245–252 (2008).</li>
                    <li id='ref20'>Seitz, K. W., Lazar, C. S., Hinrichs, K.-U., Teske, A.P., and Baker, B. J. Genomic reconstruction of a novel, deeply branched sediment archaeal phylum with pathways for acetogenesis and sulfur reduction. <i>ISME J.</i> 10: 1696–1705 (2016).</li>
                    <li id='ref21'>Thomas, T., Gilbert, J., and Meyer, F. Metagenomics – A guide from sampling to data analysis. <i>Microb. Info. Exp.</i> 2 (2012).</li>
                    <li id='ref22'>Gawad, C., Koh, W., and Quake, S. R. Single-cell genome sequencing: Current state of the science. <i>Nat. Rev. Genet.</i> 17: 175–188 (2016).</li>
                    <li id='ref23'>Dean, F. B., Nelson, J. R., Giesler, T. L., and Lasken, R. S. Rapid amplification of plasmid and phage DNA using phi29 DNA polymerase and multiply-primed rolling circle amplification. <i>Genome Res.</i> 11: 1095–1099 (2001).</li>
                    <li id='ref24'>Guy, L., and Ettema, T. J. G. The archaeal “TACK” superphylum and the origin of eukaryotes. <i>Trends Microbiol.</i> 19: 580–587 (2011).</li>
                    <li id='ref25'>Rinke, C. <i>et al.</i> Insights into the phylogeny and coding potential of microbial dark matter. <i>Nature</i> 499: 431–437 (2013).</li>
                    <li id='ref26'>Neyman, J. Molecular studies of evolution: A source of novel statistical problems. In <i>Statistical Decision Theory and Related Topics</i> (ed. Gupta, S. S., and Yackel, J.), pp. 1–27 (Academic Press, 1971).</li>
                    <li id='ref27'>Fitch, W. M. Toward defining the course of evolution: Minimum change for a specific tree topology. <i>Syst. Biol.</i> 20: 406–416 (1971).</li>
                    <li id='ref28'>Sober, E. The contest between parsimony and likelihood. <i>Syst. Biol.</i> 53: 644–653 (2004).</li>
                    <li id='ref29'>Rannala, B., and Yang, Z. Probability distribution of molecular evolutionary trees: A new method of phylogenetic inference. <i>J. Mol. Evol.</i> 43: 304–311 (1996).</li>
                    <li id='ref30'>Lees, J. A. <i>et al.</i> Evaluation of phylogenetic reconstruction methods using bacterial whole genomes: A simulation based study. <i>Wellcome Open Res.</i> 3 (2018).</li>
                    <li id='ref31'>Huelsenbeck, J. P., Ronquist, F., Nielsen, R., and Bollback, J. P. Bayesian inference of phylogeny and its impact on evolutionary biology. <i>Science</i> 294: 2310 (2001).</li>
                    <li id='ref32'>Pardi, F., and Gascuel, O. Distance-based phylogenetic inference. In <i>The Encyclopedia of Evolutionary Biology</i> (ed. Kliman, R. M.), pp. 458–465 (Academic Press, 2016).</li>
                    <li id='ref33'>Saitou, N., and Nei, M. The neighbor-joining method: A new method for reconstructing phylogenetic trees. <i>Mol. Biol. Evol.</i> 4: 406–425 (1987).</li>
                    <li id='ref34'>Van Noorden, R., Maher, B., and Nuzzo, R. The top 100 papers. <i>Nature</i> 514: 550–553 (2014).</li>
                    <li id='ref35'>Yang, Z., and Rannala, B. Molecular phylogenetics: Principles and practice. <i>Nat. Rev. Genet.</i> 13: 303–314 (2012).</li>
                    <li id='ref36'>Gascuel, O. Getting a tree fast: Neighbor joining and distance-based methods. <i>Curr. Protoc. Bioinformatics</i> 1: 6.3.1-6.3.23 (2003).</li>
                    <li id='ref37'>Zhou, X., Shen, X.-X., Hittinger, C. T., and Rokas, A. Evaluating fast maximum likelihood-based phylogenetic programs using empirical phylogenomic data sets. <i>Mol. Biol. Evol.</i> 35: 486–503 (2018).</li>
                    <li id='ref38'>Bader, D. A. Computational biology and high-performance computing. <i>Commun. ACM Bioinformatics</i> 47: 34–41 (2004).</li>
                    <li id='ref39'>Lanyon, S. M. Detecting internal inconsistencies in distance data. <i>Syst. Biol.</i> 34: 397–403 (1985).</li>
                    <li id='ref40'>Felsenstein, J. Confidence limits on phylogenies: An approach using the bootstrap. <i>Evolution</i> 39: 783–791 (1985).</li>
                    <li id='ref41'>Woese, C. R. Bacterial evolution. <i>Microbiol. Rev.</i> 51: 221–271 (1987).</li>
                    <li id='ref42'>Stamatakis, A. Phylogenetic models of rate heterogeneity: A high performance computing perspective. In <i>Proceedings 20th IEEE International Parallel & Distributed Processing Symposium</i> (2006).</li>
                    <li id='ref43'>De Soete, G. A least squares algorithm for fitting additive trees to proximity data. <i>Psychometrika</i> 48: 621–626 (1983).</li>
                    <li id='ref44'>Lake, J. A. Origin of the eukaryotic nucleus determined by rate-invariant analysis of rRNA sequences. <i>Nature</i> 331: 184–186 (1988).</li>
                    <li id='ref45'>Baldauf, S. L., Palmer, J. D., and Doolittle, W. F. The root of the universal tree and the origin of eukaryotes based on elongation factor phylogeny. <i>Proc. Natl. Acad. Sci. U.S.A.</i> 93: 7749 (1996).</li>
                    <li id='ref46'>Tourasse, N. J., and Gouy, M. Accounting for evolutionary rate variation among sequence sites consistently changes universal phylogenies deduced from rRNA and protein-coding genes. <i>Mol. Phylogenetics Evol.</i> 13: 159–168 (1999).</li>
                    <li id='ref47'>Cox, C. J., Foster, P. G., Hirt, R. P., Harris, S. R., and Embley, T. M. The archaebacterial origin of eukaryotes. <i>Proc. Natl. Acad. Sci. U.S.A.</i> 105: 20356 (2008).</li>
                    <li id='ref48'>Foster, P. G., Cox, C. J., and Embley, T. M. The primary divisions of life: a phylogenomic approach employing composition-heterogeneous methods. <i>Philos. Trans. R. Soc. B</i> 364: 2197–2207 (2009).</li>
                    <li id='ref49'>Eme, L., Spang, A., Lombard, J., Stairs, C. W., and Ettema, T. J. G. Archaea and the origin of eukaryotes. <i>Nat. Rev. Microbiol.</i> 15: 711–723 (2017).</li>
                    <li id='ref50'>Zaremba-Niedzwiedzka, K., Caceres, E.F., Saw, J.H., Bäckström, D., Juzokaite, L., Vancaester, E., et al. (2017) Asgard archaea illuminate the origin of eukaryotic cellular complexity. <i>Nature</i> 541: 353–358.</li>
                    <li id='ref51'>Da Cunha, V., Gaia, M., Gadelle, D., Nasir, A., and Forterre, P. Lokiarchaea are close relatives of Euryarchaeota, not bridging the gap between prokaryotes and eukaryotes. <i>PLoS Genet.</i> 13: e1006810 (2017).</li>
                    <li id='ref52'>Spang, A. <i>et al.</i> Asgard archaea are the closest prokaryotic relatives of eukaryotes. <i>PLoS Genet.</i> 14: e1007080 (2018).</li>
                    <li id='ref53'>MacLeod, F., Kindler, G. S., Wong, H. L., Chen, R., and Burns, B. P. Asgard archaea: Diversity, function, and evolutionary implications in a range of microbiomes. <i>AIMS Microbiol.</i> 5: 48–61 (2019).</li>
                    <li id='ref54'>Forterre, P. The common ancestor of Archaea and Eukarya was not an archaeon. <i>Archaea</i> (2013).</li>
                    <li id='ref55'>Weiss, M. C., Preiner, M., Xavier, J. C., Zimorski, V., and Martin, W. F. The last universal common ancestor between ancient Earth chemistry and the onset of genetics. <i>PLoS Genet.</i> 14: e1007518 (2018).</li>
                    <li id='ref56'>Stockbridge, R. B., Lewis, C. A., Yuan, Y., and Wolfenden, R. Impact of temperature on the time required for the establishment of primordial biochemistry, and for the evolution of enzymes. <i>Proc. Natl. Acad. Sci. U.S.A.</i> 107: 22102 (2010).</li>
                    <li id='ref57'>Lombard, J., López-García, P., and Moreira, D. The early evolution of lipid membranes and the three domains of life. <i>Nat. Rev. Microbiol.</i> 10: 507–515 (2012).</li>
                </ol>
            </div>
        </>)
    }
}

export default ThreeDomains2019
