import React from "react"

class HouseCampaign2018 extends React.Component {
    render() {
        return (<>
            <div className='preface'>
                <p><b>Editor:</b> Mark Stencel</p>
                <figure className='fig'>
                    <img
                        src={require("../images/posts/house-campaign-2018/leadership.jpg").default}
                        alt={"House Minority Leader Nancy Pelosi of California, center, speaks on election night at the Hyatt Regency Washington hotel."}
                        style={{ width: "100%" }}
                    />
                    <figcaption>House Minority Leader <b>Nancy Pelosi</b> of California, center, speaks on election night at the Hyatt Regency Washington hotel. She is accompanied by House Democrats, from left to right: Rep. <b>Barbara Lee</b>, Calif., then a favorite for party caucus chair; Rep. <b>G.K. Butterfield</b>, N.C.; incoming Majority Leader <b>Steny Hoyer</b>, Md.; incoming Majority Whip <b>Jim Clyburn</b>, S.C.; incoming Black Caucus Chair <b>Karen Bass</b>, Calif; and incoming Assistant Democratic Leader <b>Ben Ray Luján</b>, N.M. (Photo: Jacquelyn Martin/AP)</figcaption>
                </figure>
                <div className='line-break' />
            </div>
            <div className='content'>
                <p><b>FIRST CONGRESSIONAL DISTRICT, N.C.</b> — On election night, House Minority Leader Nancy Pelosi, surrounded by fellow Democratic leaders and her grandchildren, stood triumphant in Washington as she declared victory in the U.S. House of Representatives.</p>
                <p>There on stage, amid some of the most powerful figures in Democratic politics, was Rep. G.K. Butterfield.</p>
                <p><a href="https://www.cnn.com/2018/09/17/politics/house-democrats-leadership/index.html">Unlike the others</a>, the North Carolina congressman—<a href="https://degette.house.gov/about/chief-deputy-whip">one of eight assistants</a> to the No. 2 House Democrat’s assistant, and a former chair of the Congressional Black Caucus—did not get there from his fame or his position in the leadership. Instead, it was his longtime contributions to the party that placed him alongside Democratic giants, and it was the same fundraising machine that consumed most of his campaign finances this year.</p>
                <p>Representing the 1st District, Butterfield has held a safe seat for his entire political career, and this year’s election was no exception. After being uncontested in the primary, he faced the Republican challenger Roger Allison—unknown, inexperienced and <a href="https://twitter.com/RogerWAllison/status/1011809632149561344">ignored by his party</a>—who had <a href="https://twitter.com/RogerWAllison/status/985531607628075009">embraced President Donald Trump</a> in a district that had not voted Republican since 1883.</p>
                <p>Though Butterfield trounced his opponent <a href="https://projects.fivethirtyeight.com/2018-midterm-election-forecast/house/north-carolina/1/">as expected</a>, donors still gave nearly $714,000 to Butterfield, according to filings with the <a href="https://www.fec.gov/data/candidate/H4NC01046/?cycle=2018">Federal Election Commission</a>. In turn, Butterfield burned through that and more this election cycle, drawing from his last two-year cycle’s reserves to spend about $807,000.</p>
                <p>So where exactly did this money come from? And where did it go?</p>

                <h4>Moneyed Interests</h4>
                <p>Of the $714,000 that Butterfield raised, only about 14 percent came from individual donors, FEC filings showed. Instead, the vast majority of his war chest—$615,000, or 86 percent—came from political action committees, which <a href="https://www.fec.gov/help-candidates-and-committees/registering-ssf/understanding-ssf-and-its-connected-organization/">represent members and shareholders</a> of corporations, trade groups and labor unions.</p>
                <p>Some of these PACs represented groups typical for a Democrat, like the Teamsters Union and the National Education Association—<a href="https://yourbusiness.azcentral.com/top-10-labor-unions-4190.html"> two of the largest unions</a> in America and <a href="https://www.opensecrets.org/orgs/list.php?cycle=ALL">historically powerful backers</a> of the Democratic Party. Each gave $5,000 to Butterfield’s campaign.</p>
                <p>But the motives of other PACs were less clear.</p>
                <p>Some of them represented medical and pharmaceutical interests, like GlaxoSmithKline and the Community Oncology Alliance, each of which gave him $5,000. Other PACs backed telecom giants like Verizon, tobacco producers like Altria or power companies like Duke Energy. Still others included Microsoft, Coca-Cola, Clorox, Lexus, Lowe’s, Lockheed Martin, the National Pork Producers Council and the American Crystal Sugar Company, which gave him the <a href="https://www.fec.gov/help-candidates-and-committees/making-disbursements-pac/contribution-limits-nonconnected-pacs/">maximum allowed amount</a> of $10,000.</p>
                <p>Though Butterfield’s donor list seemed scattershot, the donors all had one interest in common—his seat in the powerful House Energy and Commerce Committee. Specifically, Butterfield serves on subcommittees for health, energy, communications and technology, giving him <a href="https://butterfield.house.gov/about/committees-and-caucuses">sweeping jurisdiction</a> over all these PACs’ interests—food safety, drug prices, coal emissions, broadband coverage and more.</p>
                <p>“It’s pure self-interest,” said David Rohde, political science professor at Duke University, who studies national elections. “They want certain policies to be adopted, and, if [their candidate] gets elected, that raises the chances they’ll get that something they want.”</p>
                <p>Pope McCorkle III, professor of the practice at Duke’s Sanford School of Public Policy and a former political consultant to North Carolina Democrats, agreed with Rohde. But he cautioned against reading the donations as conflicts of interest.</p>
                <p>“You would think that it would have some influence,” McCorkle said. “But it’s hard to prove that any congressperson would be dumb enough to inflame everyone back home, just to get a couple thousand dollars from a PAC.”</p>
                <p>Instead, he said corporate gifts mostly buy access, where a congressman can be convinced to nudge Washington on some low-profile regulation. And, though companies are often reluctant to tie themselves to political candidates, “Big Pharma and these highly regulated industries feel like they need to play ball.”</p>
                <p>As McCorkle predicted, Butterfield received about a quarter of his campaign funds from health-care, biomedical and pharmaceutical interests—some of the <a href="https://www.mercatus.org/publication/mclaughlin-sherouse-list-10-most-regulated-industries-2014">most regulated industries in America</a>. He received another 13 percent from telecommunications interests and 5 percent from power companies, which are also strictly regulated.</p>
                <p>Besides the PAC money, Butterfield received about $99,000, or 14 percent, of his campaign funds from individual donors, many of whom said they donated regardless of his safe incumbency.</p>
                <p>“I was happy that G.K. did not face serious opposition in his race,” said Hugh D. Cox, a Greenville-based lawyer and Butterfield’s classmate from law school, who contributed <a href="https://www.fec.gov/data/receipts/?two_year_transaction_period=2004&data_type=processed&committee_id=C00401190&contributor_name=Hugh+Cox&min_date=01/01/2003&max_date=12/31/2004">$6,250</a> to the campaign over 14 years. “G.K. Butterfield is our champion against the forces of gradual fascism and financial inequality… I am confident that G.K. receives the great majority of his money from small donors like me.”</p>
                <p>For one donor, not even Butterfield’s name seemed to have played a role.</p>
                <p>“I gave $1,000 to Abigail because my daughter asked me to do it,” said John Semcken from California, apparently referring to a <a href="https://abigailspanberger.com/">Virginia Democrat</a> who was elected this year. “I don’t know anything about the congresswoman.”</p>
                <p>Semcken’s real estate office did not return a request for clarification.</p>

                <h4>Party Man</h4>
                <p>Butterfield spent $807,000 this election cycle, outspending his Republican opponent almost 29-fold. In doing so, he emptied three-fifths of his $1,370,000 war chest, including the rollover from the previous election cycle, into what was virtually an uncontested election.</p>
                <p>With no major campaign to run, he gave most of his money back to the party, our analysis of the FEC data found.</p>
                <p>Butterfield gave about $62,000 to the state Democratic Party and $17,000 to its various county offices inside his district. And he gave more than $250,000—almost a third of his expenditures—to the Democratic Congressional Campaign Committee, the campaign arm for House Democrats.</p>
                <p>According to McCorkle, the DCCC expects certain dues from its House members, especially from safe ones like Butterfield who can focus on fundraising rather than re-election.</p>
                <p>“I think the David Prices and the Butterfields try to meet those [dues] out of the goodness of their hearts, because they’re party people,” McCorkle said, referring to Rep. David Price, his <a href="https://www.dailytarheel.com/article/2015/10/nc-rep-price-leads-by-faith-and-scholarship">former colleague</a> and another longtime Democrat in North Carolina. McCorkle <a href="https://www.fec.gov/data/disbursements/?two_year_transaction_period=2010&data_type=processed&committee_id=C00195628&recipient_name=McCorkle+Policy+Consulting&min_date=01/01/2009&max_date=12/31/2010">advised</a> Price’s campaign from 2003 to 2009, according to the FEC.</p>
                <p>Besides party contributions, Butterfield also gave at least $75,800 directly to fellow Democrats across the ballot, from city council to the U.S. Senate. Of the 36 North Carolinian candidates whom he supported, 28 were running for an office inside his district.</p>
                <p>“Even if nobody is running against you right now, it’s a way to make friends,” McCorkle said. “It’s a smart move for the future.”</p>
                <p>In fact, support for political allies was what spurred one Butterfield supporter to give $2,000 to his campaign.</p>
                <p>“I’ve given money to politics for most of my adult life,” said Cynthia McClain-Hill, managing director of a Californian lobbying firm. “And among the things I know that [incumbents] use their campaign dollars not necessarily for their re-election, but to support other candidates with similar values as well.”</p>
                <p>All that support came at a cost, however. According to FEC filings, Butterfield paid nearly $126,000 to the Frost Group, a Washington political consulting firm, for fundraising expenses.</p>
                <p>Butterfield’s office did not return repeated requests for comment, and neither did the firm. But such consulting firms “discipline the candidate so that the candidate has a rigorous schedule of fundraising calls,” McCorkle said.</p>

                <h4>Emergency Savings, Just in Case</h4>
                <p>Fundraising made up the bulk of Butterfield’s spending, but it did not account for all of it.</p>
                <p>While it wasn’t the <a href="https://www.nytimes.com/interactive/2017/06/20/us/politics/georgia-6th-most-expensive-house-election.html">record-setting $29 million</a> that fueled Jon Ossoff’s 2017 House bid, Butterfield still spent about $250,000 on day-to-day expenses for his re-election—a sizable amount for a safe incumbent.</p>
                <p>Butterfield also finished the cycle with more than $560,000 in the bank—again, a substantial amount, but <a href="https://www.washingtonpost.com/politics/as-2018-elections-near-potential-2020-presidential-candidates-jockey-for-money/2018/07/29/44a8acd0-90d9-11e8-bcd5-9d911c784c38_story.html">not enough</a> to fund some higher aspirations, like a Senate seat or a governorship.</p>
                <p>Instead, according to Rohde, Butterfield may be trying to intimidate challengers from the left.</p>
                <p>Since the 1st District is very strongly liberal, Butterfield’s seat may be attractive to other Black politicians, younger and more progressive, who may see themselves as successors to the 71-year-old Democrat. Anyone who topples Butterfield would inherit his safe incumbency—possibly for decades.</p>
                <p>A large stockpile of campaign savings, Rohde said, would discourage possible challengers from running in the first place.</p>
                <p>“Historically, there are lots of candidates who got to their position and sat back and didn’t raise money for 10 or 20 years, until somebody came along and surprised them.” Rohde said. “Like Ms. Ocasio-Cortez.”</p>
                <div className='line-break' />

                <div className='refs'>
                    <h4>Sources</h4>
                    <p>Rep. Diana DeGette (D-Colo.), “<a href="https://degette.house.gov/about/chief-deputy-whip">Chief deputy whip</a>,” accessed Dec. 2, 2018.</p>
                    <p>“<a href="https://www.cnn.com/2018/09/17/politics/house-democrats-leadership/index.html">3 options that could await House Democrats after the midterms</a>,” CNN, Sept. 17, 2018.</p>
                    <p>Roger Allison, <a href="https://twitter.com/RogerWAllison/status/1011809632149561344">tweet asking President Donald Trump for an endorsement</a>, June 26, 2018.</p>
                    <p>Roger Allison, <a href="https://twitter.com/RogerWAllison/status/985531607628075009">tweet supporting Trump</a>, April 15, 2018.</p>
                    <p>“The historical atlas of United States congressional districts,” by Ken Martis, 1982.</p>
                    <p>FiveThirtyEight, <a href="https://projects.fivethirtyeight.com/2018-midterm-election-forecast/house/north-carolina/1/">House forecast for North Carolina 1st District</a>, accessed Nov. 27, 2018.</p>
                    <p>Federal Election Commission, <a href="https://www.fec.gov/data/candidate/H4NC01046/?cycle=2018">Butterfield for Congress financial filings for 2017–18</a>, accessed from Nov. 12 to Dec. 15, 2018.</p>
                    <p>“<a href="https://www.theguardian.com/world/2010/nov/03/us-midterm-election-results-tea-party">U.S. midterm election results herald new political era as Republicans take House</a>,” The Guardian, Nov. 3, 2010.</p>
                    <p>N.C. State Board of Elections, <a href="https://er.ncsbe.gov/?election_dt=11/06/2018&county_id=0&office=FED&contest=1175">unofficial general election results for Nov. 6, 2018</a>, last updated Nov. 27, 2018.</p>
                    <p>Federal Election Commission, <a href="https://www.fec.gov/data/candidate/H8NC01104/?cycle=2018">Roger Allison for Congress financial filings for 2017–18</a>, accessed from Nov. 12 to 26, 2018.</p>
                    <p>Federal Election Commission, “<a href="https://www.fec.gov/help-candidates-and-committees/registering-ssf/understanding-ssf-and-its-connected-organization/">Understanding the SSF and its connected organization</a>,” accessed Dec. 10, 2018.</p>
                    <p>“<a href="https://yourbusiness.azcentral.com/top-10-labor-unions-4190.html">Top 10 labor unions</a>,” The Arizona Republic, April 16, 2018.</p>
                    <p>Federal Election Commission, “<a href="https://www.fec.gov/help-candidates-and-committees/making-disbursements-pac/contribution-limits-nonconnected-pacs/">Contribution limits for nonconnected PACs</a>,” accessed Dec. 3, 2018.</p>
                    <p>Rep. G.K. Butterfield (D-N.C.), “<a href="https://butterfield.house.gov/about/committees-and-caucuses">Committees & caucuses</a>,” accessed Dec 1, 2018.</p>
                    <p>Interview with David Rohde, political science professor at Duke University, Nov. 27, 2018.</p>
                    <p>Interview with Pope McCorkle III, professor of the practice at Duke University’s Sanford School of Public Policy and former Democratic consultant, Dec. 3, 2018.</p>
                    <p>Mercatus Center at George Mason University, “<a href="https://www.mercatus.org/publication/mclaughlin-sherouse-list-10-most-regulated-industries-2014">The McLaughlin-Sherouse list: The 10 most-regulated industries of 2014</a>,” Jan. 21, 2017.</p>
                    <p>Email interview with Hugh D. Cox, disability lawyer in Greenville, who gave $1,000 to Butterfield’s campaign, Nov. 27, 2018.</p>
                    <p>Federal Election Commission, <a href="https://www.fec.gov/data/receipts/?two_year_transaction_period=2004&data_type=processed&committee_id=C00401190&contributor_name=Hugh+Cox&min_date=01/01/2003&max_date=12/31/2004">Butterfield for Congress receipts from Hugh Cox for 2004–18</a>, accessed Dec. 15, 2018.</p>
                    <p>Voicemail interview with John Semcken, senior vice president at Majestic Realty Co., who gave $1,000 each to the <a href="https://www.fec.gov/data/receipts/?two_year_transaction_period=2018&cycle=2018&data_type=processed&contributor_name=Semcken,+John&min_date=01/01/2017&max_date=12/04/2018&line_number=F3-11AI">campaigns</a> of Butterfield, Abigail Spanberger (D-Va.) and Shawn Nelson (R-Calif.), Nov. 27, 2018.</p>
                    <p><a href="https://abigailspanberger.com/">Abigail Spanberger for Congress</a>, accessed Nov. 27, 2018.</p>
                    <p>“<a href="https://www.dailytarheel.com/article/2015/10/nc-rep-price-leads-by-faith-and-scholarship">N.C. Rep. David Price leads by faith and scholarship</a>,” The Daily Tar Heel, Oct. 5, 2015.</p>
                    <p>Federal Election Commission, <a href="https://www.fec.gov/data/disbursements/?two_year_transaction_period=2004&data_type=processed&committee_id=C00195628&recipient_name=McCorkle+Policy+Consulting&min_date=01/01/2003&max_date=12/31/2004">Price for Congress disbursements to McCorkle Policy Consulting for 2003–10</a>, accessed Dec. 11, 2018.</p>
                    <p>Phone interview with Cynthia McClain-Hill, managing director of the Californian lobbying firm Strategic Counsel, who gave $2,000 to Butterfield’s campaign, Nov. 29, 2018.</p>
                    <p>“<a href="https://www.nytimes.com/interactive/2017/06/20/us/politics/georgia-6th-most-expensive-house-election.html">Who financed the Georgia Sixth, the most expensive House election ever</a>,” The New York Times, June 20, 2017.</p>
                    <p>Federal Election Commission, <a href="https://www.fec.gov/data/committee/C00630426/?cycle=2018">Jon Ossoff for Congress financial filings for 2017–18</a>, accessed Dec. 15, 2018.</p>
                    <p>“<a href="https://www.washingtonpost.com/politics/as-2018-elections-near-potential-2020-presidential-candidates-jockey-for-money/2018/07/29/44a8acd0-90d9-11e8-bcd5-9d911c784c38_story.html">As 2018 elections near, potential 2020 presidential candidates jockey for money</a>,” The Washington Post, July 29, 2018.</p>
                    <div className='line-break' />

                    <h4>Missed Connections</h4>
                    <p>Meaghan Lynch, Butterfield’s communicators director, did not return repeated requests for comment about the congressman’s fundraising and spending.</p>
                    <p>John Aldrich, Pfizer–Pratt University professor of political science at Duke University who specializes in American politics, did not respond to an interview request about campaign finances.</p>
                    <p>D. Sunshine Hillygus, professor of political science and public policy at Duke University who researches campaign effects, declined an interview request about campaign finances.</p>
                    <p>Jennifer Frost, founder and president of the Frost Group, a Washington fundraising firm, did not return a request for comment about the firm’s services.</p>
                    <p>Curtis Wynn, president and chief executive of Roanoke Electric Cooperative, declined to comment about his $1,000 donation to Butterfield’s campaign.</p>
                    <p>The following donors did not return interview requests asking why they donated to Butterfield’s campaign and whether they knew about the race’s safe nature:</p>
                    <ul>
                        <li>Don Attore, principal of a Californian law firm, who gave $1,000;</li>
                        <li>Mamie Banks, special education teacher at Northern Vance High School in Henderson, who gave $500;</li>
                        <li>Jack Barbour, psychiatrist in Los Angeles, who gave $2,000;</li>
                        <li>Jacqueline Beatty-Smith, senior consultant at the HR Consulting Group in Maryland, who gave $1,000;</li>
                        <li>Charisse Bremond-Weaver, president and chief executive of the Brotherhood Crusade, a Los Angeles Black community group, who gave $1,000;</li>
                        <li>Jonathan Charleston, principal of a Fayetteville law firm, who gave $1,000;</li>
                        <li>Robert A. Farris, Jr., attorney at Farris & Farris in Wilson, who gave $1,000;</li>
                        <li>Daryl L. Osby, fire chief of the Los Angeles County Fire Department, who gave $1,000;</li>
                        <li>Alvin Leonard Pittman, a lawyer in Los Angeles, who gave $1,000;</li>
                        <li>Hubert D. Pope, funeral home owner in Roanoke Rapids, who provided catering and transportation services and a $1,500 contribution;</li>
                        <li>Mark Ridley-Thomas, supervisor of Los Angeles County, who catered for and donated $1,661.53 to the campaign;</li>
                        <li>Vernon Sampson, management and program analyst at U.S. Customs and Border Protection, who gave $1,000;</li>
                        <li>Alexander T. Silbey, senior government relations consultant at Becker, who gave $2,200;</li>
                        <li>Wendell Stemley, president of the construction management firm Black IPO, who gave $2,000;</li>
                        <li>Vickie F. Stephenson, corporate chief executive of Stephenson General Contractors in Smithfield, who gave $2,000; and</li>
                        <li>Patrice Willoughby, lobbyist at Heather Podesta’s Washington-based firm Invariant, who gave $2,000.</li>
                    </ul>
                </div>
            </div>
        </>)
    }
}

export default HouseCampaign2018
