import React from "react"

class HowTo2020 extends React.Component {
    render() {
        return (<>
            <div class='content'>
                <div class='line-break' />


            </div>
        </>)
    }
}

export default HowTo2020
